
import React from 'react'
import ReactFlagsSelect from "react-flags-select";
const CustomerInfo = ({app,selected,setSelected,name,setName,number,setNumber,nameColor,setNameColor,numberColor,setNumberColor,fonts
,setFonts,fontsNum,setFontsNum
}) => {
  
  
  
  const BRGColorforNumber=()=>{
    console.log("BRGColorforNumber");
    app.contentWindow.postMessage("BRGColorforNumber : "+"#004225", "*"); setNumberColor("#004225")
  }
  const BlackColorforNumber=()=>{
    console.log("BlackColorforNumber");
    app.contentWindow.postMessage("BlackColorforNumber : "+"#000000", "*"); setNumberColor("#000000")
  }
  const WhiteColorforNumber=()=>{
    console.log("WhiteColorforNumber");
    app.contentWindow.postMessage("WhiteColorforNumber : "+"#fff", "*"); setNumberColor("#fff")
  }
  const RedColorforNumber=()=>{
    console.log("RedColorforNumber");
    app.contentWindow.postMessage("RedColorforNumber : "+"#FF0000", "*"); setNumberColor("#FF0000")
  }
  const LimeColorforNumber=()=>{
    console.log("LimeColorforNumber");
    app.contentWindow.postMessage("LimeColorforNumber : "+"#00FF00", "*"); setNumberColor("#00FF00")
  }
  const YellowColorforNumber=()=>{
    console.log("YellowColorforNumber");
    app.contentWindow.postMessage("YellowColorforNumber : "+"#FFFF00", "*"); setNumberColor("#FFFF00")
  }
  const BlueColorforNumber=()=>{
    console.log("BlueColorforNumber");
    app.contentWindow.postMessage("BlueColorforNumber : "+"#0000FF", "*"); setNumberColor("#0000FF")
  }
  const OrangeColorforNumber=()=>{
    console.log("OrangeColorforNumber");
    app.contentWindow.postMessage("OrangeColorforNumber : "+"#FF6600", "*"); setNumberColor("#FF6600")
  }
  const GoldColorforNumber=()=>{
    console.log("GoldColorforNumber");
    app.contentWindow.postMessage("GoldColorforNumber : "+"#FFD700", "*"); setNumberColor("#FFD700")
  }
  const SilverColorforNumber=()=>{
    console.log("SilverColorforNumber");
    app.contentWindow.postMessage("SilverColorforNumber : "+"#BBC2CC", "*"); setNumberColor("#BBC2CC")
  }
  const GMGColorforNumber=()=>{
    console.log("GMGColorforNumber");
    app.contentWindow.postMessage("GMGColorforNumber : "+"#5F6670", "*"); setNumberColor("#5F6670")
  }
  // --------------------------------------------------------------------
  const BRGColorforName=()=>{
    console.log("BRGColorforName");
    app.contentWindow.postMessage("BRGColorforName : "+"#004225", "*"); setNameColor("#004225")
  }
  const BlackColorforName=()=>{
    console.log("BlackColorforName");
    app.contentWindow.postMessage("BlackColorforName : "+"#000000", "*"); setNameColor("#000000")
  }
  const WhiteColorforName=()=>{
    console.log("WhiteColorforName");
    app.contentWindow.postMessage("WhiteColorforName : "+"#fff", "*"); setNameColor("#fff")
  }
  const RedColorforName=()=>{
    console.log("RedColorforName");
    app.contentWindow.postMessage("RedColorforName : "+"#FF0000", "*"); setNameColor("#FF0000")
  }
  const LimeColorforName=()=>{
    console.log("LimeColorforName");
    app.contentWindow.postMessage("LimeColorforName : "+"#00FF00", "*"); setNameColor("#00FF00")
  }
  const YellowColorforName=()=>{
    console.log("YellowColorforName");
    app.contentWindow.postMessage("YellowColorforName : "+"#FFFF00", "*"); setNameColor("#FFFF00")
  }
  const BlueColorforName=()=>{
    console.log("BlueColorforName");
    app.contentWindow.postMessage("BlueColorforName : "+"#0000FF", "*"); setNameColor("#0000FF")
  }
  const OrangeColorforName=()=>{
    console.log("OrangeColorforName");
    app.contentWindow.postMessage("OrangeColorforName : "+"#FF6600", "*"); setNameColor("#FF6600")
  }
  const GoldColorforName=()=>{
    console.log("GoldColorforName");
    app.contentWindow.postMessage("GoldColorforName : "+"#FFD700", "*"); setNameColor("#FFD700")
  }
  const SilverColorforName=()=>{
    console.log("SilverColorforName");
    app.contentWindow.postMessage("SilverColorforName : "+"#BBC2CC", "*"); setNameColor("#BBC2CC")
  }
  const GMGColorforName=()=>{
    console.log("GMGColorforName");
    app.contentWindow.postMessage("GMGColorforName : "+"#5F6670", "*"); setNameColor("#5F6670")
  }
 
  const Fonts=()=>{
    console.log("Fonts");
    app.contentWindow.postMessage("Fonts : "+fonts, "*");
  }
  
  const FontsNum=()=>{
    console.log("FontsNum");
    app.contentWindow.postMessage("FontsNum : "+fontsNum, "*");
  }
  


  const NameColor=(e)=>{
    
   
    console.log("NameColor");
    app.contentWindow.postMessage("NameColor : "+e, "*");
  }
  const NumberColor=(e)=>{
    
    console.log("NumberColor");
    app.contentWindow.postMessage("NumberColor : "+e, "*");
  }
  

  const InfoName = () => {
    console.log("InfoName");
    app.contentWindow.postMessage("InfoName : "+name, "*");
  };
  const InfoNumber = () => {
    console.log("InfoNumber");
    app.contentWindow.postMessage("InfoNumber : "+number, "*");
  };
  
  return (
    <>
        <div className='CustomerInfo'>
        <div id="Color">
            <div className='row'>
                <p>Enter your Name : </p>
                <input onChange={(e)=>setName(e.target.value)} onKeyDown={()=>InfoName()} onClick={()=>InfoName()} type="text" className='form-control'/>
                <p>Change Font : </p>
                <select onClick={(e)=>Fonts()} selected={fonts} onChange={(e)=>{setFonts(e.target.value)}} className="form-control">
                  <option >Select Font</option>
                  <option value="Paladins">Paladins</option>
                  <option value="Star 7">Star 7</option>
                  <option value="American Captain">American Captain</option>
                  <option value="Dassault">Dassault</option>
                  <option value="Ferro Rosso">Ferro Rosso</option>
                  {/* <option value="Hunterra">Hunterra</option> */}
                  <option value="High Swift">High Swift</option>
                  <option value="Rugen Exp">Rugen Exp</option>
                  <option value="Heist">Heist</option>
                  <option value="Arial">Arial</option>
                  <option value="Calibri">Calibri</option>
                  <option value="Century Gothic">Century Gothic</option>
                  
                </select>
               
                <div className='row'>
                  <p>Change Name Color : </p>
                  <div onClick={()=>BRGColorforName()} className='color British_Racing_Green'></div>
                <div onClick={()=>BlackColorforName()} className='color Black'></div>
                <div onClick={()=>WhiteColorforName()} className='color White'></div>
                <div onClick={()=>RedColorforName()} className='color Red'></div>
                <div onClick={()=>LimeColorforName()}className='color Lime'></div>
                <div onClick={()=>YellowColorforName()} className='color Yellow'></div>
                <div onClick={()=>BlueColorforName()} className='color Blue'></div>
                <div onClick={()=>OrangeColorforName()} className='color Orange'></div>
                <div onClick={()=>GoldColorforName()} className='color Gold'></div>
                <div onClick={()=>SilverColorforName()} className='color Silver'></div>
                <div onClick={()=>GMGColorforName()} className='color Gun_Metal_Grey'></div>
                <center><p>Choose Custom Color</p></center>
                <input type="color"  onChange={(e)=>{
                  setNameColor(e.target.value)
                  NameColor(e.target.value)}}/>
                <p>Choose your Country Flag : </p>
                <ReactFlagsSelect
                 
                  selected={selected}
                  onSelect={(code) => {
                      setSelected(code);
                      console.log("CountryFlag");
                      app.contentWindow.postMessage("CountryFlag : "+code, "*");
                  
                  }}
                  
                  countries={["CA", "US", 'AR','AT','AU','BH','BE','BR','CL','CN','CO','HR','CU','CZ','DK','DO','GB',
                  'EG','ET','FI','FR','DE','GR','HU','IN','ID','IE','IL','IT','JM','JP','KW','MT','ME','MC','MA','NL','NZ','NG','NO','QA','PK','PH','PL','PT',
                  'RO','SA','ZA','KR','ES','LK','SE','CH','TW','TH','TR','UA','AE','VE','VN','VI',
                ]}
                />
                <p>Enter your Number : </p>
                <input onChange={(e)=>setNumber(e.target.value)}  onKeyDown={()=>InfoNumber()} onClick={()=>InfoNumber()} type="number" className='form-control'/>

                
                <p>Change Font : </p>
             <select onClick={(e)=>FontsNum()} selected={fonts} onChange={(e)=>{setFontsNum(e.target.value)}} className="form-control">
              <option >Select Font</option>
              <option value="Paladins">Paladins</option>
              <option value="Star 7">Star 7</option>
              <option value="American Captain">American Captain</option>
              <option value="Dassault">Dassault</option>
              <option value="Ferro Rosso">Ferro Rosso</option>
              {/* <option value="Hunterra">Hunterra</option> */}
              <option value="High Swift">High Swift</option>
              <option value="Rugen Exp">Rugen Exp</option>
              <option value="Heist">Heist</option>
              <option value="Arial">Arial</option>
              <option value="Calibri">Calibri</option>
              <option value="Century Gothic">Century Gothic</option>
              
            </select>
            <p>Change Number Color : </p>
            <div onClick={()=>BRGColorforNumber()} className='color British_Racing_Green'></div>
                <div onClick={()=>BlackColorforNumber()} className='color Black'></div>
                <div onClick={()=>WhiteColorforNumber()} className='color White'></div>
                <div onClick={()=>RedColorforNumber()} className='color Red'></div>
                <div onClick={()=>LimeColorforNumber()}className='color Lime'></div>
                <div onClick={()=>YellowColorforNumber()} className='color Yellow'></div>
                <div onClick={()=>BlueColorforNumber()} className='color Blue'></div>
                <div onClick={()=>OrangeColorforNumber()} className='color Orange'></div>
                <div onClick={()=>GoldColorforNumber()} className='color Gold'></div>
                <div onClick={()=>SilverColorforNumber()} className='color Silver'></div>
                <div onClick={()=>GMGColorforNumber()} className='color Gun_Metal_Grey'></div>
                <center><p>Choose Custom Color</p></center>
            <input type="color"  onChange={(e)=>{
              setNumberColor(e.target.value)
              NumberColor(e.target.value)}}/>
                </div>
                </div>
               
                
                
                
            </div>
            <div className='row'>
            
            
            
            
            

                {/* <div onClick={()=>BRGColorforSeat()} className='colorinfo British_Racing_Green'></div>
                <div onClick={()=>BlackColorforSeat()} className='colorinfo Black'></div>
                <div onClick={()=>WhiteColorforSeat()} className='colorinfo White'></div>
                <div onClick={()=>RedColorforSeat()} className='colorinfo Red'></div>
                <div onClick={()=>LimeColorforSeat()}className='color Lime'></div>
                <div onClick={()=>YellowColorforSeat()} className='color Yellow'></div>
                <div onClick={()=>BlueColorforSeat()} className='color Blue'></div>
                <div onClick={()=>OrangeColorforSeat()} className='color Orange'></div>
                <div onClick={()=>GoldColorforSeat()} className='color Gold'></div>
                <div onClick={()=>SilverColorforSeat()} className='color Silver'></div>
                <div onClick={()=>GMGColorforSeat()} className='color Gun_Metal_Grey'></div> */}
            </div>
        </div>
    </>
  )
}

export default CustomerInfo