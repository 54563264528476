import  Axios  from 'axios';
import React from 'react'

const Login = ({setCount,setConfigurationDetails,setLoggedinUserName,app,setLogOutButton,setModalShow,setDashboard,setLoggedInUserDashboard,setLoginButton,setLoggedinUserID}) => {
    const [username,setUserName]=React.useState("");
    const [password,setPassword]=React.useState("");
    const [users,setUsers]=React.useState([]);
    const [error,setError]=React.useState("");

    React.useEffect(()=>{
        // Axios.get('https://carconfigurator.herokuapp.com/users').then(async(response)=>{
        // setUsers(response.data);
        
    // });
    
    Axios({
        method: 'get',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        url: 'https://carconfigurator.herokuapp.com/users',
        
      }).then(function (response) {
        
        setUsers(response.data);
      });
    
    },[])
    const ConfigurationDetails=(id)=>{
        // Axios.get(`https://carconfigurator.herokuapp.com/configurationdetails/${id}`).then(async(response)=>{
        //   setConfigurationDetails(response.data);
        // });
        Axios({
            method: 'get',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            url: `https://carconfigurator.herokuapp.com/configurationdetails/${id}`,
            
          }).then(function (response) {
            ;
            setConfigurationDetails(response.data);
          });
      }
    const Login=()=>{
        {users.map((val,key)=>{
            if((username=== val.userEmail) && (password === val.userPassword)){
                console.log("LoggedinUser");
                app.contentWindow.postMessage("LoggedinUser","*");
                setError("Success");
                setLoggedinUserID(val._id);
                setLoggedinUserName(val.userName);
                setLoginButton(true);
                setModalShow(false);
                setLogOutButton(false);
                setLoggedInUserDashboard(true);
                ConfigurationDetails(val._id);
                
            }else if((username!= val.userEmail) && (password != val.userPassword))
                {setError("Invalid Email or Password!");}
            else if((username==="Robert@gmail.com")&& (password === "123456789")){
                console.log("LoggedinUser");
                app.contentWindow.postMessage("LoggedinUser","*");
                setDashboard(true);
                setLoggedinUserID(username);
                setLoginButton(true);
                setLogOutButton(false);
                setLoggedInUserDashboard(false);
                setCount(1)
            }
        })}
        
    }




  return (
    <div id="Login">
        <section>
            {/* <form > */}
            <div>
                <div className="ErrorDialog">
                    <span>{error}</span>
                </div>
                <label>Email :</label>
                <input autoComplete='off' 
                onChange={(e)=>setUserName(e.target.value)}
                value={username}
                type="text" 
                className='form-control' required/>
            </div>
            <div>
                <label>Password :</label>
                <input autoComplete='off' 
                onChange={(e)=>setPassword(e.target.value)}
                value={password}
                type="password" 
                className='form-control' required/>
            </div>
            <div>
                <button  style={{backgroundColor:"red",fontWeight:"bold"}} className='form-control' 
                onClick={()=>{
                    Login();}
                }
                >Login</button>
            </div>
            {/* </form> */}
            
        </section>
    </div>
  )
}

export default Login