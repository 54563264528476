// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getStorage} from "firebase/storage"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAzG-3T7Zh7hwM1A23OXDNvPaHTFyUGuKQ",
  authDomain: "carconfigurator-f2290.firebaseapp.com",
  projectId: "carconfigurator-f2290",
  storageBucket: "carconfigurator-f2290.appspot.com",
  messagingSenderId: "569131133188",
  appId: "1:569131133188:web:6cdc4eb77176aa6ec68851"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const storage=getStorage(app);