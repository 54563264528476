import React from 'react'
import Axios from "axios";
import swal from 'sweetalert';

const Signup = ({setDashboard,setModalShow}) => {
    const [username,setUserName]=React.useState("");
    const [email,setEmail]=React.useState("");
    const [password,setPassword]=React.useState("");
    const [lname,setLname]=React.useState("");
    
     const RegisterUser=()=>{
        Axios.post("https://carconfigurator.herokuapp.com/register",{
            userName:username,
            userEmail:email,
            userPassword:password,
            userLName:lname
        }); 
        setModalShow(false);
        // setDashboard(true);
        swal(`Congrats ${username}!`, "Now you can save the Configurations! Please Login to save Configurations", "success");
    }
    
  return (
    
        <>
        <section id="Signup">
            <form action="">
                <div>
                    <label>First Name :</label>
                    <input autoComplete='off' 
                    onChange={(e)=>setUserName(e.target.value)}
                    value={username}
                    type="text" 
                    className='form-control' required/>
                </div>
                <div>
                    <label>Last Name :</label>
                    <input autoComplete='off' 
                    onChange={(e)=>setLname(e.target.value)}
                    value={lname}
                    type="text" 
                    className='form-control' required/>
                </div>
                <div>
                    <label>Email :</label>
                    <input autoComplete='off' 
                    onChange={(e)=>setEmail(e.target.value)}
                    value={email}
                    type="email" 
                    className='form-control' required/>
                </div>
                <div>
                    <label>Password :</label>
                    <input autoComplete='off' 
                    onChange={(e)=>setPassword(e.target.value)}
                    value={password}
                    type="password" 
                    className='form-control' required/>
                </div>
                
                <div>
                    <button style={{backgroundColor:"red",fontWeight:"bold"}} onClick={()=>{RegisterUser();}} className='form-control' type="submit">SignUp</button>
                </div>
            </form>
        </section>

        </>
    
  )
}

export default Signup