import React from "react";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
// import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Logo from "./Logo";
import Color from "./Color";
import Graphics from "./Graphics";
import Axios from "axios";
import CustomerInfo from "./CustomerInfo";
import jsPDF from 'jspdf';
import { Modal,Button } from "react-bootstrap";
import swal from 'sweetalert';
import Monitor from "./Monitor";



export default function CustomAccordion({ConfigurationDetails,setLoggedInUserDashboard,app,loggedinUserId,loggedinUserName,setAllConfigs}) {



  // Custome Info 
  const [selected, setSelected] = React.useState("");
  const [name,setName]=React.useState("");
  const [number,setNumber]=React.useState(0);
  const [nameColor,setNameColor]=React.useState();
  const [numberColor,setNumberColor]=React.useState();
  const [fonts,setFonts]=React.useState("");
  const [fontsNum,setFontsNum]=React.useState("");
  const [modalFirstName,setModalFirstName]=React.useState("");
  const [modalLastName,setModalLastName]=React.useState("");
  const [modalPhone,setModalPhone]=React.useState("");
  const [modalEmail,setModalEmail]=React.useState("");
  const [modalCompany,setModalCompany]=React.useState("");
  const [modalCountry,setModalCountry]=React.useState("");
  const [modalEventDate,setModalEventDate]=React.useState("");
  const [modalnote,setModalNote]=React.useState("");
  const [purchaseCount,setpurchaseCount]=React.useState(0);
  const [rentalCount,setrentalCount]=React.useState(0);
  const [eventCount,seteventCount]=React.useState(0);

  const Purchase=()=>{
    if(purchaseCount===0){
      setpurchaseCount(1);
    }else{
      setpurchaseCount(0);
    }
  }

  const Rental=()=>{
    if(rentalCount===0){
      setrentalCount(1);
    }else{
      setrentalCount(0);
    }
  }
  const Event=()=>{
    if(eventCount===0){
      seteventCount(1);
    }else{
      seteventCount(0);
    }
  }

  // Logos
  const [logo,setLogo]=React.useState("");
  const [logoforMonitor,setLogoforMonitor]=React.useState("");
  const [logoforTopSidePanels,setLogoforTopSidePanels]=React.useState("");
  const [logoforBottomSidePanels,setLogoforBottomSidePanels]=React.useState("");
  const [logoforSeatBack,setLogoforSeatBack]=React.useState("");








  // Colors
  const [shirtFabric,setShirtFabric]=React.useState();
  const [frontDust,setFrontDust]=React.useState();
  const [leftDust,setLeftDust]=React.useState();
  const [rightDust,setRightDust]=React.useState();

  const [seatfabric,setseatfabric]=React.useState();
  const [monitor,setmonitor]=React.useState();
  const [resLogo,setresLogo]=React.useState();
  const [pedalMount,setpedalMount]=React.useState();
  const [insideFrame,setinsideFrame]=React.useState();
  const [sideseatMount,setsideseatMount]=React.useState();
  const [steeringWheels,setsteeringWheels]=React.useState();
  const [mainFrame,setmainFrame]=React.useState();
  const [floor,setfloor]=React.useState();
  const [steeringColumn,setsteeringColumn]=React.useState();
  const [monitorMount,setmonitorMount]=React.useState();
  const [toprightpanel,settoprightpanel]=React.useState();
  const [topleftpanel,settopleftpanel]=React.useState();
  const [bottomleftpanel,setbottomleftpanel]=React.useState();
  const [bottomrightpanel,setbottomrightpanel]=React.useState();
  const [speaker,setSpeaker]=React.useState();
  const [show, setShow] = React.useState(false);
  const [confignameModal,setconfignameModal]=React.useState(false);
  const [configName,setConfigName]=React.useState("")
  const handleClose = () => setShow(false);
 
  const d = new Date();
  let date = d.toString();
  
  const ShowName=()=>{
    if(loggedinUserId==="none"){
      swal(`Warning!`, "You have to login first to save your Configuration.", "error");
    }
    else{
      setconfignameModal(true);
    }

  }
  const handleShow = () => {
    
   
      swal(`Congrats!`, "You have successfully saved your Configuration.", "success");
  
      let temp=[];
      const CreateConfiguration=()=>{
        Axios.post("https://carconfigurator.herokuapp.com/configuration",{userId:loggedinUserId,
        userConfig:[...temp[0]],
        configDate:date,
        username:loggedinUserName,
      },{"Accept": "application/json", 'Content-Type': 'multipart/form-data'  }).then(response=>
            console.log("Ghr se",response)

        )

        Axios({
          method: 'get',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          url: 'https://carconfigurator.herokuapp.com/allconfigs',
          
        }).then(function (response) {
          ConfigurationDetails(loggedinUserId);
          setAllConfigs(response.data);
        });
        
      // window.location.reload();
      
      
      
        
        }
      console.log("SavePDF");
      app.contentWindow.postMessage("SavePDF","*");
      window.onmessage=(event)=>{
        if(typeof(event.data)==="object"){
          temp.push(event.data);
          console.log(temp.length,temp[0])
          if(temp.length===1){
        
            CreateConfiguration();
            
        }else if(temp.length>1){
          console.log("Nhin chaly ga");
          
          
          
          // window.location.reload()
        }
          
        }
  
      }
     
    
    
    
    
      
    
    
  }

  
 
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
 
  return (
    <>
    <Modal 
    
    show={confignameModal}
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
    >
      
    <Modal.Body>
    <div id="Updatearea">
      <label>Name your Configuration</label>
      <input onChange={(e)=>setConfigName(e.target.value)} type="text" className="form-control"/>
      <button style={{marginRight:"2rem"}} type="submit" onClick={()=>{
        console.log("Configuration Name")
        app.contentWindow.postMessage("Configuration Name"+configName,"*")
        handleShow();
        
        setconfignameModal(false);
        setLoggedInUserDashboard(true);

        }} >Submit</button>
        <button onClick={()=>setconfignameModal(false)}>Close</button>
    </div>
    </Modal.Body>
  </Modal>

    <Modal className="Modal" show={show} onHide={handleClose}>
        <Modal.Header  closeButton>
        <center><h4 style={{color:"white",alignItems:"center",justifyContent:"center"}}>REQUEST A PROPOSAL</h4></center>
        </Modal.Header>
        <Modal.Body>
            <div style={{display:"flex",justifyContent:"space-between"}}>
            <p style={{color:"white"}}>Contact Details</p>
            <p style={{color:"white"}}>* Required</p>
            </div>
            <form noValidate={false}>
            <div className="row">
              <div className="col-md-6  col-sm-6">
              <label style={{color:"white"}}>First Name: *</label>
              <input onChange={(e)=>setModalFirstName(e.target.value)} type="text"  placeholder="" style={{marginTop:"0",width:"90%"}}/>
              </div>
              <div className="col-md-6 col-sm-6">
                <label style={{color:"white"}}>Last Name: *</label>
                <input onChange={(e)=>setModalLastName(e.target.value)} type="text"/>
              </div>
            </div>
            <div className="row">
            <div className="col-md-6 col-sm-6">
            <label style={{color:"white"}}>Email: *</label>
            <input onChange={(e)=>setModalEmail(e.target.value)} type="email"   style={{width:"90%"}}/>
            </div>
            <div className="col-md-6 col-sm-6">
            <label style={{color:"white"}}>Phone Number: *</label>
            <input onChange={(e)=>setModalPhone(e.target.value)} type="number"    />
            </div>

            </div>



            <div className="row">
            <div className="col-md-6 col-sm-6">
            <label style={{color:"white"}}>Company: *</label>
            <input onChange={(e)=>setModalCompany(e.target.value)} type="text"  style={{width:"90%"}}  />
            </div>
            <div className="col-md-6 col-sm-6">
            <label style={{color:"white"}}>Country: *</label>
            <input onChange={(e)=>setModalCountry(e.target.value)}  type="text"    />
            </div>

            </div>
            <p style={{color:"white"}}>Options:</p>
            <p style={{color:"white"}}>Check all that apply</p>
            <input onClick={()=>Purchase()} style={{fontSize:"1rem"}} type="checkbox"/> <label style={{color:"white",marginRight:"4rem"}}>Purchase</label>
            <input onClick={()=>Rental()} style={{fontSize:"1rem"}} type="checkbox"/> <label style={{color:"white"}}>Rental</label>
            <br/>
            <div style={{display:"flex",justifyContent:"space-between"}} >
            <div>
            <input onClick={()=>Event()} style={{fontSize:"1rem"}} type="checkbox"/> <label style={{color:"white"}}>Event</label>
            </div>
            <div>
            <label onChange={(e)=>{setModalEventDate(e.target.value.toString());
            console.log(e.target.value)}
            } style={{color:"white",marginRight:".3rem"}}>Event Date </label>
            <input type="date" onChange={(e)=>{
              console.log(e.target.value)
            }}/>
            </div>
            </div>
            <p style={{color:"white"}}>Notes</p>
            <div className="row">
            <textarea  onClick={(e)=>setModalNote(e.target.value)} style={{height:"8rem"}}></textarea>
            </div>
            </form>
          
        </Modal.Body>
        <Modal.Footer>
          
          <Button type="submit" style={{color:"white",backgroundColor:"maroon",border:"none"}} 
          
           >
            Save & Submit
          </Button>
        </Modal.Footer>
      </Modal>


    <div id="CustomAccordion">
    <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5bh-content"
          id="panel5bh-header"
        >
         <h1>Monitor</h1>
        </AccordionSummary> 
        <AccordionDetails>
          <Monitor app={app}/>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
         <h1>Colors</h1>
          {/* <Typography sx={{ color: 'text.secondary' }}>
            Change Colors of different Objects
          </Typography> */}
        </AccordionSummary> 
        <AccordionDetails>
          <Color app={app}
          seatfabric={seatfabric} setseatfabric={setseatfabric} monitor={monitor} setmonitor={setmonitor} resLogo={resLogo} setresLogo={setresLogo} pedalMount={pedalMount} setpedalMount={setpedalMount} 
          insideFrame={insideFrame} setinsideFrame={setinsideFrame} sideseatMount={sideseatMount} setsideseatMount={setsideseatMount} steeringWheels={steeringWheels} setsteeringWheels={setsteeringWheels} mainFrame={mainFrame} setmainFrame={setmainFrame} floor={floor} setfloor={setfloor} steeringColumn={steeringColumn} setsteeringColumn={setsteeringColumn} 
          monitorMount={monitorMount} setmonitorMount={setmonitorMount} topleftpanel={topleftpanel} settopleftpanel={settopleftpanel} toprightpanel={toprightpanel} settoprightpanel={settoprightpanel} bottomleftpanel={bottomleftpanel} setbottomleftpanel={setbottomleftpanel} bottomrightpanel={bottomrightpanel} setbottomrightpanel={setbottomrightpanel} 
          speaker={speaker} setSpeaker={setSpeaker} shirtFabric={shirtFabric} setShirtFabric={setShirtFabric} frontDust={frontDust} leftDust={leftDust} rightDust={rightDust} setFrontDust={setFrontDust}
          setLeftDust={setLeftDust} setRightDust={setRightDust}
          
          
          
          
          />
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <h1>Logos</h1>
          {/* <Typography sx={{ width: '33%', flexShrink: 0 }}>
            Logos
          </Typography> */}
          {/* <Typography sx={{ color: 'text.secondary' }}>Upload your Logo & for monitor, 4 side panels, seat</Typography> */}
        </AccordionSummary>
        <AccordionDetails>
          <Logo app={app} logo={logo}setLogo={setLogo}logoforMonitor={logoforMonitor}setLogoforMonitor={setLogoforMonitor}logoforTopSidePanels={logoforTopSidePanels}setLogoforTopSidePanels={setLogoforTopSidePanels}
          logoforBottomSidePanels={logoforBottomSidePanels}setLogoforBottomSidePanels={setLogoforBottomSidePanels}logoforSeatBack={logoforSeatBack}setLogoforSeatBack={setLogoforSeatBack}/>
        </AccordionDetails>
      </Accordion>
      
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <h1>Graphics</h1>
          {/* <Typography sx={{ width: '33%', flexShrink: 0 }}>
           Seat
          </Typography>
          <Typography sx={{ color: 'text.secondary' }}>
            Filtering has been entirely disabled for whole web server
          </Typography> */}
        </AccordionSummary>
        <AccordionDetails>
         <Graphics app={app}/>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <h1>Info</h1>
          {/* <Typography sx={{ width: '33%', flexShrink: 0 }}>Side Panels</Typography>
          <Typography sx={{ color: 'text.secondary' }}>
            Filtering has been entirely disabled for whole web server
          </Typography> */}
        </AccordionSummary>
        <AccordionDetails>
          <CustomerInfo app={app} selected={selected} setSelected={setSelected}name={name}setName={setName}
           number={number}setNumber={setNumber}nameColor={nameColor}setNameColor={setNameColor}numberColor={numberColor}setNumberColor={setNumberColor}fonts
           ={fonts}setFonts={setFonts}fontsNum={fontsNum}setFontsNum={setFontsNum}/>
        </AccordionDetails>
      </Accordion>
     <div className="row">
     <button onClick={()=>ShowName() } style={{background:"#FF0000",color:"white",padding:".5rem"}} className="SaveConfigButton">Save Configuration</button>

     </div>
    </div>
    </>
  );
}
 