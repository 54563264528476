import React from 'react'
import Monitor1 from "../images/monitor1.jpeg"
import Monitor2 from "../images/Monitor2.jpg"
import {AiFillCheckCircle} from "react-icons/ai"
const Monitor = ({app}) => {
  const [monitor1,setMonitor1]=React.useState("none");
  const [monitor2,setMonitor2]=React.useState("none");
  return (
    <div id="Monitor">
        <div className='singleMonitor'>
        <div className='row'>
        <center><img src={Monitor1} onClick={()=>{
          setMonitor1("block");
          setMonitor2("none");
            console.log("Monitor : Samsung 55 Odyssey Ark 4K");
            app.contentWindow.postMessage("Monitor : Samsung 55 Odyssey Ark 4K","*")
        }} className='form-group'  />
        </center>
        <AiFillCheckCircle style={{fontSize:"80px",position:"absolute",color:"black",display:monitor1}}/>
        </div>
        <center><p>Samsung 55" Odyssey Ark 4K</p></center>
        </div>
        <div className='singleMonitor' >
       <div className='row'>
       <center> <img src={Monitor2} onClick={()=>{
        setMonitor1("none");
        setMonitor2("block");
            console.log("Monitor : Samsung 49 Odyssey Neo G9");
            app.contentWindow.postMessage("Monitor : Samsung 49 Odyssey Neo G9","*")
        }} className='form-group' /></center>
        <AiFillCheckCircle style={{fontSize:"80px",position:"absolute",color:"black",display:monitor2}}/>

       </div>
        <center><p>Samsung 49" Odyssey Neo G9</p></center>
        </div>
        
    </div>
  )
}

export default Monitor