import React from 'react'
// import {Modal,Button} from "react-bootstrap"
const Color = ({app,seatfabric,setseatfabric,monitor,setmonitor,resLogo,setresLogo,pedalMount,setpedalMount,
insideFrame,setinsideFrame,sideseatMount,setsideseatMount,steeringWheels,setsteeringWheels,mainFrame,setmainFrame,floor,setfloor,steeringColumn,setsteeringColumn,
monitorMount,setmonitorMount,topleftpanel,settopleftpanel,toprightpanel,settoprightpanel,bottomleftpanel,setbottomleftpanel,bottomrightpanel,setbottomrightpanel,
speaker,setSpeaker,shirtFabric, setShirtFabric, frontDust, leftDust, rightDust, setFrontDust,
setLeftDust, setRightDust,
}) => {
  // const [showSeatFabric, setShowSeatFabric] = React.useState(false);
  // const handleSeatFabricClose = () => setShowSeatFabric(false);
  // const handleSeatFabricShow = () => setShowSeatFabric(true);

   
  
// --------------------------------------------------------------------
const CustomColorShirtFabric=(e)=>{
  console.log("CustomColorShirtFabric");
  app.contentWindow.postMessage("CustomColorShirtFabric : "+e,"*");
}
const CustomColorFrontDust=(e)=>{
  console.log("CustomColorFrontDust");
  app.contentWindow.postMessage("CustomColorFrontDust : "+e,"*");
}
const CustomColorLeftDust=(e)=>{ 
  console.log("CustomColorLeftDust");
  app.contentWindow.postMessage("CustomColorLeftDust : "+e,"*");
}
const CustomColorRightDust=(e)=>{
  console.log("CustomColorRightDust");
  app.contentWindow.postMessage("CustomColorRightDust : "+e,"*");
}


  const CustomColorSpeaker=(e)=>{
  console.log("CustomColorSpeaker");
  app.contentWindow.postMessage("CustomColorSpeaker : "+e,"*");
}

  const CustomColorSeatFabric=(e)=>{
    console.log("CustomColorSeatFabric");
    app.contentWindow.postMessage("CustomColorSeatFabric : "+e, "*");
  }
  const CustomColorMonitor=(e)=>{
    console.log("CustomColorMonitor");
    app.contentWindow.postMessage("CustomColorMonitor : "+e, "*");
  }
  const CustomColorResLogo=(e)=>{
    console.log("CustomColorResLogo");
    app.contentWindow.postMessage("CustomColorResLogo : "+e, "*");
  }
  const CustomColorPedalMount=(e)=>{
    console.log("CustomColorPedalMount");
    app.contentWindow.postMessage("CustomColorPedalMount : "+e, "*");
  }
  const CustomColorInsideFrame=(e)=>{
    console.log("CustomColorInsideFrame");
    app.contentWindow.postMessage("CustomColorInsideFrame : "+e, "*");
  }
  const CustomColorsideSeatMount=(e)=>{
    console.log("CustomColorsideSeatMount");
    app.contentWindow.postMessage("CustomColorsideSeatMount : "+e, "*");
  }
  const CustomColorSteeringWheels=(e)=>{
    console.log("CustomColorSteeringWheels");
    app.contentWindow.postMessage("CustomColorSteeringWheels : "+e, "*");
  }
  const CustomColorMainFrame=(e)=>{
    console.log("CustomColorMainFrame");
    app.contentWindow.postMessage("CustomColorMainFrame : "+e, "*");
  }
  const CustomColorFloor=(e)=>{
    console.log("CustomColorFloor");
    app.contentWindow.postMessage("CustomColorFloor : "+e, "*");
  }
  const CustomColorSteeringColumn=(e)=>{
    console.log("CustomColorSteeringColumn");
    app.contentWindow.postMessage("CustomColorSteeringColumn : "+e, "*");
  }
  const CustomColorMonitorMount=(e)=>{
    console.log("CustomColorMonitorMount");
    app.contentWindow.postMessage("CustomColorMonitorMount : "+e, "*");
  }
  const CustomColorToprightPanel=(e)=>{
    console.log("CustomColorToprightPanel");
    app.contentWindow.postMessage("CustomColorToprightPanel : "+e, "*");
  }
  const CustomColorTopleftPanel=(e)=>{
    console.log("CustomColorTopleftPanel");
    app.contentWindow.postMessage("CustomColorTopleftPanel : "+e, "*");
  }
  const CustomColorbottomleftPanel=(e)=>{
    console.log("CustomColorbottomleftPanel");
    app.contentWindow.postMessage("CustomColorbottomleftPanel : "+e, "*");
  }
  const CustomColorbottomrightPanel=(e)=>{
    console.log("CustomColorbottomrightPanel");
    app.contentWindow.postMessage("CustomColorbottomrightPanel : "+e, "*");
  }
// ---------------------------------------------------------------------------
  const BRGColorforSeat=()=>{
    console.log("BRGColorforSeat");
    app.contentWindow.postMessage("BRGColorforSeat : "+"#004225", "*"); 
    setseatfabric("#004225");
  }
  const BlackColorforSeat=()=>{
    console.log("BlackColorforSeat");
    app.contentWindow.postMessage("BlackColorforSeat : "+"#000000", "*"); 
    setseatfabric("#000000");
  }
  const WhiteColorforSeat=()=>{
    console.log("WhiteColorforSeat");
    app.contentWindow.postMessage("WhiteColorforSeat : "+"#fff", "*"); 
    setseatfabric("#fff");
  }
  const RedColorforSeat=()=>{
    console.log("RedColorforSeat");
    app.contentWindow.postMessage("RedColorforSeat : "+"#FF0000", "*"); 
    setseatfabric("#FF0000");
  }
  const LimeColorforSeat=()=>{
    console.log("LimeColorforSeat");
    app.contentWindow.postMessage("LimeColorforSeat : "+"#00FF00", "*"); setseatfabric("#00FF00");
  }
  const YellowColorforSeat=()=>{
    console.log("YellowColorforSeat");
    app.contentWindow.postMessage("YellowColorforSeat : "+"#FFFF00", "*"); setseatfabric("#FFFF00");
  }
  const BlueColorforSeat=()=>{
    console.log("BlueColorforSeat");
    app.contentWindow.postMessage("BlueColorforSeat : "+"#0000FF", "*"); setseatfabric("#0000FF");
  }
  const OrangeColorforSeat=()=>{
    console.log("OrangeColorforSeat");
    app.contentWindow.postMessage("OrangeColorforSeat : "+"#FF6600", "*"); setseatfabric("#FF6600");
  }
  const GoldColorforSeat=()=>{
    console.log("GoldColorforSeat");
    app.contentWindow.postMessage("GoldColorforSeat : "+"#FFD700", "*"); setseatfabric("#FFD700");
  }
  const SilverColorforSeat=()=>{
    console.log("SilverColorforSeat");
    app.contentWindow.postMessage("SilverColorforSeat : "+"#BBC2CC", "*"); setseatfabric("#BBC2CC");
  }
  const GMGColorforSeat=()=>{
    console.log("GMGColorforSeat");
    app.contentWindow.postMessage("GMGColorforSeat : "+"#5F6670", "*"); setseatfabric("#5F6670");
  }
  // ----------------------------------------------------------------------------
  const BRGColorforSpeakers=()=>{
    console.log("BRGColorforSpeakers");
    app.contentWindow.postMessage("BRGColorforSpeakers : "+"#004225", "*"); setSpeaker("#004225")
    
  }
  const BlackColorforSpeakers=()=>{
    console.log("BlackColorforSpeakers");
    app.contentWindow.postMessage("BlackColorforSpeakers : "+"#000000", "*"); setSpeaker("#000000")
  }
  const WhiteColorforSpeakers=()=>{
    console.log("WhiteColorforSpeakers");
    app.contentWindow.postMessage("WhiteColorforSpeakers : "+"#fff", "*"); setSpeaker("#fff")
  }
  const RedColorforSpeakers=()=>{
    console.log("RedColorforSpeakers");
    app.contentWindow.postMessage("RedColorforSpeakers : "+"#FF0000", "*"); setSpeaker("#FF0000")
  }
  const LimeColorforSpeakers=()=>{
    console.log("LimeColorforSpeakers");
    app.contentWindow.postMessage("LimeColorforSpeakers : "+"#00FF00", "*"); setSpeaker("#00FF00")
  }
  const YellowColorforSpeakers=()=>{
    console.log("YellowColorforSpeakers");
    app.contentWindow.postMessage("YellowColorforSpeakers : "+"#FFFF00", "*"); setSpeaker("#FFFF00")
  }
  const BlueColorforSpeakers=()=>{
    console.log("BlueColorforSpeakers");
    app.contentWindow.postMessage("BlueColorforSpeakers : "+"#0000FF", "*"); setSpeaker("#0000FF")
  }
  const OrangeColorforSpeakers=()=>{
    console.log("OrangeColorforSpeakers");
    app.contentWindow.postMessage("OrangeColorforSpeakers : "+"#FF6600", "*"); setSpeaker("#FF6600")
  }
  const GoldColorforSpeakers=()=>{
    console.log("GoldColorforSpeakers");
    app.contentWindow.postMessage("GoldColorforSpeakers : "+"#FFD700", "*"); setSpeaker("#FFD700")
  }
  const SilverColorforSpeakers=()=>{
    console.log("SilverColorforSpeakers");
    app.contentWindow.postMessage("SilverColorforSpeakers : "+"#BBC2CC", "*"); setSpeaker("#BBC2CC")
  }
  const GMGColorforSpeakers=()=>{
    console.log("GMGColorforSpeakers");
    app.contentWindow.postMessage("GMGColorforSpeakers : "+"#5F6670", "*"); setSpeaker("#5F6670")
  }
  // ----------------------------------------------------------------------------
  const BRGColorforMonitor=()=>{
    console.log("BRGColorforMonitor");
    app.contentWindow.postMessage("BRGColorforMonitor : "+"#004225", "*"); setmonitor("#004225")
  }
  const BlackColorforMonitor=()=>{
    console.log("BlackColorforMonitor");
    app.contentWindow.postMessage("BlackColorforMonitor : "+"#000000", "*"); setmonitor("#000000")
  }
  const WhiteColorforMonitor=()=>{
    console.log("WhiteColorforMonitor");
    app.contentWindow.postMessage("WhiteColorforMonitor : "+"#fff", "*"); setmonitor("#fff")
  }
  const RedColorforMonitor=()=>{
    console.log("RedColorforMonitor");
    app.contentWindow.postMessage("RedColorforMonitor : "+"#FF0000", "*"); setmonitor("#FF0000")
  }
  const LimeColorforMonitor=()=>{
    console.log("LimeColorforMonitor");
    app.contentWindow.postMessage("LimeColorforMonitor : "+"#00FF00", "*"); setmonitor("#00FF00")
  }
  const YellowColorforMonitor=()=>{
    console.log("YellowColorforMonitor");
    app.contentWindow.postMessage("YellowColorforMonitor : "+"#FFFF00", "*"); setmonitor("#FFFF00")
  }
  const BlueColorforMonitor=()=>{
    console.log("BlueColorforMonitor");
    app.contentWindow.postMessage("BlueColorforMonitor : "+"#0000FF", "*"); setmonitor("#0000FF")
  }
  const OrangeColorforMonitor=()=>{
    console.log("OrangeColorforMonitor");
    app.contentWindow.postMessage("OrangeColorforMonitor : "+"#FF6600", "*"); setmonitor("#FF6600")
  }
  const GoldColorforMonitor=()=>{
    console.log("GoldColorforMonitor");
    app.contentWindow.postMessage("GoldColorforMonitor : "+"#FFD700", "*"); setmonitor("#FFD700")
  }
  const SilverColorforMonitor=()=>{
    console.log("SilverColorforMonitor");
    app.contentWindow.postMessage("SilverColorforMonitor : "+"#BBC2CC", "*"); setmonitor("#BBC2CC")
  }
  const GMGColorforMonitor=()=>{
    console.log("GMGColorforMonitor");
    app.contentWindow.postMessage("GMGColorforMonitor : "+"#5F6670", "*"); setmonitor("#5F6670")
  }
  // ----------------------------------------------------------------------------
  const BRGColorforRESLogo=()=>{
    console.log("BRGColorforRESLogo");
    app.contentWindow.postMessage("BRGColorforRESLogo : "+"#004225", "*"); setresLogo("#004225")
  }
  const BlackColorforRESLogo=()=>{
    console.log("BlackColorforRESLogo");
    app.contentWindow.postMessage("BlackColorforRESLogo : "+"#000000", "*"); setresLogo("#000000")
  }
  const WhiteColorforRESLogo=()=>{
    console.log("WhiteColorforRESLogo");
    app.contentWindow.postMessage("WhiteColorforRESLogo : "+"#fff", "*"); setresLogo("#fff")
  }
  const RedColorforRESLogo=()=>{
    console.log("RedColorforRESLogo");
    app.contentWindow.postMessage("RedColorforRESLogo : "+"#FF0000", "*"); setresLogo("#FF0000")
  }
  const LimeColorforRESLogo=()=>{
    console.log("LimeColorforRESLogo");
    app.contentWindow.postMessage("LimeColorforRESLogo : "+"#00FF00", "*"); setresLogo("#00FF00")
  }
  const YellowColorforRESLogo=()=>{
    console.log("YellowColorforRESLogo");
    app.contentWindow.postMessage("YellowColorforRESLogo : "+"#FFFF00", "*"); setresLogo("#FFFF00")
  }
  const BlueColorforRESLogo=()=>{
    console.log("BlueColorforRESLogo");
    app.contentWindow.postMessage("BlueColorforRESLogo : "+"#0000FF", "*"); setresLogo("#0000FF")
  }
  const OrangeColorforRESLogo=()=>{
    console.log("OrangeColorforRESLogo");
    app.contentWindow.postMessage("OrangeColorforRESLogo : "+"#FF6600", "*"); setresLogo("#FF6600")
  }
  const GoldColorforRESLogo=()=>{
    console.log("GoldColorforRESLogo");
    app.contentWindow.postMessage("GoldColorforRESLogo : "+"#FFD700", "*"); setresLogo("#FFD700")
  }
  const SilverColorforRESLogo=()=>{
    console.log("SilverColorforRESLogo");
    app.contentWindow.postMessage("SilverColorforRESLogo : "+"#BBC2CC", "*"); setresLogo("#BBC2CC")
  }
  const GMGColorforRESLogo=()=>{
    console.log("GMGColorforRESLogo");
    app.contentWindow.postMessage("GMGColorforRESLogo : "+"#5F6670", "*"); setresLogo("#5F6670")
  }
   // ----------------------------------------------------------------------------
   const BRGColorforMountColor=()=>{
    console.log("BRGColorforMountColor");
    app.contentWindow.postMessage("BRGColorforMountColor : "+"#004225", "*"); setpedalMount("#004225")
  }
  const BlackColorforMountColor=()=>{
    console.log("BlackColorforMountColor");
    app.contentWindow.postMessage("BlackColorforMountColor : "+"#000000", "*"); setpedalMount("#000000")
  }
  const WhiteColorforMountColor=()=>{
    console.log("WhiteColorforMountColor");
    app.contentWindow.postMessage("WhiteColorforMountColor : "+"#fff", "*"); setpedalMount("#fff")
  }
  const RedColorforMountColor=()=>{
    console.log("RedColorforMountColor");
    app.contentWindow.postMessage("RedColorforMountColor : "+"#FF0000", "*"); setpedalMount("#FF0000")
  }
  const LimeColorforMountColor=()=>{
    console.log("LimeColorforMountColor");
    app.contentWindow.postMessage("LimeColorforMountColor : "+"#00FF00", "*"); setpedalMount("#00FF00")
  }
  const YellowColorforMountColor=()=>{
    console.log("YellowColorforMountColor");
    app.contentWindow.postMessage("YellowColorforMountColor : "+"#FFFF00", "*"); setpedalMount("#FFFF00")
  }
  const BlueColorforMountColor=()=>{
    console.log("BlueColorforMountColor");
    app.contentWindow.postMessage("BlueColorforMountColor : "+"#0000FF", "*"); setpedalMount("#0000FF")
  }
  const OrangeColorforMountColor=()=>{
    console.log("OrangeColorforMountColor");
    app.contentWindow.postMessage("OrangeColorforMountColor : "+"#FF6600", "*"); setpedalMount("#FF6600")
  }
  const GoldColorforMountColor=()=>{
    console.log("GoldColorforMountColor");
    app.contentWindow.postMessage("GoldColorforMountColor : "+"#FFD700", "*"); setpedalMount("#FFD700")
  }
  const SilverColorforMountColor=()=>{
    console.log("SilverColorforMountColor");
    app.contentWindow.postMessage("SilverColorforMountColor : "+"#BBC2CC", "*"); setpedalMount("#BBC2CC")
  }
  const GMGColorforMountColor=()=>{
    console.log("GMGColorforMountColor");
    app.contentWindow.postMessage("GMGColorforMountColor : "+"#5F6670", "*"); setpedalMount("#5F6670")
  }
  // ----------------------------------------------------------------------------
  const BRGColorforFrame=()=>{
    console.log("BRGColorforFrame");
    app.contentWindow.postMessage("BRGColorforFrame : "+"#004225", "*"); setinsideFrame("#004225")
  }
  const BlackColorforFrame=()=>{
    console.log("BlackColorforFrame");
    app.contentWindow.postMessage("BlackColorforFrame : "+"#000000", "*"); setinsideFrame("#000000")
  }
  const WhiteColorforFrame=()=>{
    console.log("WhiteColorforFrame");
    app.contentWindow.postMessage("WhiteColorforFrame : "+"#fff", "*"); setinsideFrame("#fff")
  }
  const RedColorforFrame=()=>{
    console.log("RedColorforFrame");
    app.contentWindow.postMessage("RedColorforFrame : "+"#FF0000", "*"); setinsideFrame("#FF0000")
  }
  const LimeColorforFrame=()=>{
    console.log("LimeColorforFrame");
    app.contentWindow.postMessage("LimeColorforFrame : "+"#00FF00", "*"); setinsideFrame("#00FF00")
  }
  const YellowColorforFrame=()=>{
    console.log("YellowColorforFrame");
    app.contentWindow.postMessage("YellowColorforFrame : "+"#FFFF00", "*"); setinsideFrame("#FFFF00")
  }
  const BlueColorforFrame=()=>{
    console.log("BlueColorforFrame");
    app.contentWindow.postMessage("BlueColorforFrame : "+"#0000FF", "*"); setinsideFrame("#0000FF")
  }
  const OrangeColorforFrame=()=>{
    console.log("OrangeColorforFrame");
    app.contentWindow.postMessage("OrangeColorforFrame : "+"#FF6600", "*"); setinsideFrame("#FF6600")
  }
  const GoldColorforFrame=()=>{
    console.log("GoldColorforFrame");
    app.contentWindow.postMessage("GoldColorforFrame : "+"#FFD700", "*"); setinsideFrame("#FFD700")
  }
  const SilverColorforFrame=()=>{
    console.log("SilverColorforFrame");
    app.contentWindow.postMessage("SilverColorforFrame : "+"#BBC2CC", "*"); setinsideFrame("#BBC2CC")
  }
  const GMGColorforFrame=()=>{
    console.log("GMGColorforFrame");
    app.contentWindow.postMessage("GMGColorforFrame : "+"#5F6670", "*"); setinsideFrame("#5F6670")
  }
  // ----------------------------------------------------------------------------
  const BRGColorforSeatMounts=()=>{
    console.log("BRGColorforSeatMounts");
    app.contentWindow.postMessage("BRGColorforSeatMounts : "+"#004225", "*"); setsideseatMount("#004225")
  }
  const BlackColorforSeatMounts=()=>{
    console.log("BlackColorforSeatMounts");
    app.contentWindow.postMessage("BlackColorforSeatMounts : "+"#000000", "*"); setsideseatMount("#000000")
  }
  const WhiteColorforSeatMounts=()=>{
    console.log("WhiteColorforSeatMounts");
    app.contentWindow.postMessage("WhiteColorforSeatMounts : "+"#fff", "*"); setsideseatMount("#fff")
  }
  const RedColorforSeatMounts=()=>{
    console.log("RedColorforSeatMounts");
    app.contentWindow.postMessage("RedColorforSeatMounts : "+"#FF0000", "*"); setsideseatMount("#FF0000")
  }
  const LimeColorforSeatMounts=()=>{
    console.log("LimeColorforSeatMounts");
    app.contentWindow.postMessage("LimeColorforSeatMounts : "+"#00FF00", "*"); setsideseatMount("#00FF00")
  }
  const YellowColorforSeatMounts=()=>{
    console.log("YellowColorforSeatMounts");
    app.contentWindow.postMessage("YellowColorforSeatMounts : "+"#FFFF00", "*"); setsideseatMount("#FFFF00")
  }
  const BlueColorforSeatMounts=()=>{
    console.log("BlueColorforSeatMounts");
    app.contentWindow.postMessage("BlueColorforSeatMounts : "+"#0000FF", "*"); setsideseatMount("#0000FF")
  }
  const OrangeColorforSeatMounts=()=>{
    console.log("OrangeColorforSeatMounts");
    app.contentWindow.postMessage("OrangeColorforSeatMounts : "+"#FF6600", "*"); setsideseatMount("#FF6600")
  }
  const GoldColorforSeatMounts=()=>{
    console.log("GoldColorforSeatMounts");
    app.contentWindow.postMessage("GoldColorforSeatMounts : "+"#FFD700", "*"); setsideseatMount("#FFD700")
  }
  const SilverColorforSeatMounts=()=>{
    console.log("SilverColorforSeatMounts");
    app.contentWindow.postMessage("SilverColorforSeatMounts : "+"#BBC2CC", "*"); setsideseatMount("#BBC2CC")
  }
  const GMGColorforSeatMounts=()=>{
    console.log("GMGColorforSeatMounts");
    app.contentWindow.postMessage("GMGColorforSeatMounts : "+"#5F6670", "*"); setsideseatMount("#5F6670")
  }
   // ----------------------------------------------------------------------------
   const BRGColorforSteeringWheel=()=>{
    console.log("BRGColorforSteeringWheel");
    app.contentWindow.postMessage("BRGColorforSteeringWheel : "+"#004225", "*"); setsteeringWheels("#004225")
  }
  const BlackColorforSteeringWheel=()=>{
    console.log("BlackColorforSteeringWheel");
    app.contentWindow.postMessage("BlackColorforSteeringWheel : "+"#000000", "*"); setsteeringWheels("#000000")
  }
  const WhiteColorforSteeringWheel=()=>{
    console.log("WhiteColorforSteeringWheel");
    app.contentWindow.postMessage("WhiteColorforSteeringWheel : "+"#fff", "*"); setsteeringWheels("#fff")
  }
  const RedColorforSteeringWheel=()=>{
    console.log("RedColorforSteeringWheel");
    app.contentWindow.postMessage("RedColorforSteeringWheel : "+"#FF0000", "*"); setsteeringWheels("#FF0000")
  }
  const LimeColorforSteeringWheel=()=>{
    console.log("LimeColorforSteeringWheel");
    app.contentWindow.postMessage("LimeColorforSteeringWheel : "+"#00FF00", "*"); setsteeringWheels("#00FF00")
  }
  const YellowColorforSteeringWheel=()=>{
    console.log("YellowColorforSteeringWheel");
    app.contentWindow.postMessage("YellowColorforSteeringWheel : "+"#FFFF00", "*"); setsteeringWheels("#FFFF00")
  }
  const BlueColorforSteeringWheel=()=>{
    console.log("BlueColorforSteeringWheel");
    app.contentWindow.postMessage("BlueColorforSteeringWheel : "+"#0000FF", "*"); setsteeringWheels("#0000FF")
  }
  const OrangeColorforSteeringWheel=()=>{
    console.log("OrangeColorforSteeringWheel");
    app.contentWindow.postMessage("OrangeColorforSteeringWheel : "+"#FF6600", "*"); setsteeringWheels("#FF6600")
  }
  const GoldColorforSteeringWheel=()=>{
    console.log("GoldColorforSteeringWheel");
    app.contentWindow.postMessage("GoldColorforSteeringWheel : "+"#FFD700", "*"); setsteeringWheels("#FFD700")
  }
  const SilverColorforSteeringWheel=()=>{
    console.log("SilverColorforSteeringWheel");
    app.contentWindow.postMessage("SilverColorforSteeringWheel : "+"#BBC2CC", "*"); setsteeringWheels("#BBC2CC")
  }
  const GMGColorforSteeringWheel=()=>{
    console.log("GMGColorforSteeringWheel");
    app.contentWindow.postMessage("GMGColorforSteeringWheel : "+"#5F6670", "*"); setsteeringWheels("#5F6670")
  }
   // ----------------------------------------------------------------------------
   const BRGColorforMainFrame=()=>{
    console.log("BRGColorforMainFrame");
    app.contentWindow.postMessage("BRGColorforMainFrame : "+"#004225", "*"); setmainFrame("#004225")
  }
  const BlackColorforMainFrame=()=>{
    console.log("BlackColorforMainFrame");
    app.contentWindow.postMessage("BlackColorforMainFrame : "+"#000000", "*"); setmainFrame("#000000")
  }
  const WhiteColorforMainFrame=()=>{
    console.log("WhiteColorforMainFrame");
    app.contentWindow.postMessage("WhiteColorforMainFrame : "+"#fff", "*"); setmainFrame("#fff")
  }
  const RedColorforMainFrame=()=>{
    console.log("RedColorforMainFrame");
    app.contentWindow.postMessage("RedColorforMainFrame : "+"#FF0000", "*"); setmainFrame("#FF0000")
  }
  const LimeColorforMainFrame=()=>{
    console.log("LimeColorforMainFrame");
    app.contentWindow.postMessage("LimeColorforMainFrame : "+"#00FF00", "*"); setmainFrame("#00FF00")
  }
  const YellowColorforMainFrame=()=>{
    console.log("YellowColorforMainFrame");
    app.contentWindow.postMessage("YellowColorforMainFrame : "+"#FFFF00", "*"); setmainFrame("#FFFF00")
  }
  const BlueColorforMainFrame=()=>{
    console.log("BlueColorforMainFrame");
    app.contentWindow.postMessage("BlueColorforMainFrame : "+"#0000FF", "*"); setmainFrame("#0000FF")
  }
  const OrangeColorforMainFrame=()=>{
    console.log("OrangeColorforMainFrame");
    app.contentWindow.postMessage("OrangeColorforMainFrame : "+"#FF6600", "*"); setmainFrame("#FF6600")
  }
  const GoldColorforMainFrame=()=>{
    console.log("GoldColorforMainFrame");
    app.contentWindow.postMessage("GoldColorforMainFrame : "+"#FFD700", "*"); setmainFrame("#FFD700")
  }
  const SilverColorforMainFrame=()=>{
    console.log("SilverColorforMainFrame");
    app.contentWindow.postMessage("SilverColorforMainFrame : "+"#BBC2CC", "*"); setmainFrame("#BBC2CC")
  }
  const GMGColorforMainFrame=()=>{
    console.log("GMGColorforMainFrame");
    app.contentWindow.postMessage("GMGColorforMainFrame : "+"#5F6670", "*"); setmainFrame("#5F6670")
  }
  // ----------------------------------------------------------------------------
  const BRGColorforFloor=()=>{
    console.log("BRGColorforFloor");
    app.contentWindow.postMessage("BRGColorforFloor : "+"#004225", "*"); setfloor("#004225")
  }
  const BlackColorforFloor=()=>{
    console.log("BlackColorforFloor");
    app.contentWindow.postMessage("BlackColorforFloor : "+"#000000", "*"); setfloor("#000000")
  }
  const WhiteColorforFloor=()=>{
    console.log("WhiteColorforFloor");
    app.contentWindow.postMessage("WhiteColorforFloor : "+"#fff", "*"); setfloor("#fff")
  }
  const RedColorforFloor=()=>{
    console.log("RedColorforFloor");
    app.contentWindow.postMessage("RedColorforFloor : "+"#FF0000", "*"); setfloor("#FF0000")
  }
  const LimeColorforFloor=()=>{
    console.log("LimeColorforFloor");
    app.contentWindow.postMessage("LimeColorforFloor : "+"#00FF00", "*"); setfloor("#00FF00")
  }
  const YellowColorforFloor=()=>{
    console.log("YellowColorforFloor");
    app.contentWindow.postMessage("YellowColorforFloor : "+"#FFFF00", "*"); setfloor("#FFFF00")
  }
  const BlueColorforFloor=()=>{
    console.log("BlueColorforFloor");
    app.contentWindow.postMessage("BlueColorforFloor : "+"#0000FF", "*"); setfloor("#0000FF")
  }
  const OrangeColorforFloor=()=>{
    console.log("OrangeColorforFloor");
    app.contentWindow.postMessage("OrangeColorforFloor : "+"#FF6600", "*"); setfloor("#FF6600")
  }
  const GoldColorforFloor=()=>{
    console.log("GoldColorforFloor");
    app.contentWindow.postMessage("GoldColorforFloor : "+"#FFD700", "*"); setfloor("#FFD700")
  }
  const SilverColorforFloor=()=>{
    console.log("SilverColorforFloor");
    app.contentWindow.postMessage("SilverColorforFloor : "+"#BBC2CC", "*"); setfloor("#BBC2CC")
  }
  const GMGColorforFloor=()=>{
    console.log("GMGColorforFloor");
    app.contentWindow.postMessage("GMGColorforFloor : "+"#5F6670", "*"); setfloor("#5F6670")
  }
  // ----------------------------------------------------------------------------
  const BRGColorforSteeringColumn=()=>{
    console.log("BRGColorforSteeringColumn");
    app.contentWindow.postMessage("BRGColorforSteeringColumn : "+"#004225", "*"); setsteeringColumn("#004225");
  }
  const BlackColorforSteeringColumn=()=>{
    console.log("BlackColorforSteeringColumn");
    app.contentWindow.postMessage("BlackColorforSteeringColumn : "+"#000000", "*"); setsteeringColumn("#000000");
  }
  const WhiteColorforSteeringColumn=()=>{
    console.log("WhiteColorforSteeringColumn");
    app.contentWindow.postMessage("WhiteColorforSteeringColumn : "+"#fff", "*"); setsteeringColumn("#fff");
  }
  const RedColorforSteeringColumn=()=>{
    console.log("RedColorforSteeringColumn");
    app.contentWindow.postMessage("RedColorforSteeringColumn : "+"#FF0000", "*"); setsteeringColumn("#FF0000");
  }
  const LimeColorforSteeringColumn=()=>{
    console.log("LimeColorforSteeringColumn");
    app.contentWindow.postMessage("LimeColorforSteeringColumn : "+"#00FF00", "*"); setsteeringColumn("#00FF00");
  }
  const YellowColorforSteeringColumn=()=>{
    console.log("YellowColorforSteeringColumn");
    app.contentWindow.postMessage("YellowColorforSteeringColumn : "+"#FFFF00", "*"); setsteeringColumn("#FFFF00");
  }
  const BlueColorforSteeringColumn=()=>{
    console.log("BlueColorforSteeringColumn");
    app.contentWindow.postMessage("BlueColorforSteeringColumn : "+"#0000FF", "*"); setsteeringColumn("#0000FF");
  }
  const OrangeColorforSteeringColumn=()=>{
    console.log("OrangeColorforSteeringColumn");
    app.contentWindow.postMessage("OrangeColorforSteeringColumn : "+"#FF6600", "*"); setsteeringColumn("#FF6600");
  }
  const GoldColorforSteeringColumn=()=>{
    console.log("GoldColorforSteeringColumn");
    app.contentWindow.postMessage("GoldColorforSteeringColumn : "+"#FFD700", "*"); setsteeringColumn("#FFD700");
  }
  const SilverColorforSteeringColumn=()=>{
    console.log("SilverColorforSteeringColumn");
    app.contentWindow.postMessage("SilverColorforSteeringColumn : "+"#BBC2CC", "*"); setsteeringColumn("#BBC2CC");
  }
  const GMGColorforSteeringColumn=()=>{
    console.log("GMGColorforSteeringColumn");
    app.contentWindow.postMessage("GMGColorforSteeringColumn : "+"#5F6670", "*"); setsteeringColumn("#5F6670");
  }
  // ----------------------------------------------------------------------------
  const BRGColorforTopLeft=()=>{
    console.log("BRGColorforTopLeft");
    app.contentWindow.postMessage("BRGColorforTopLeft : "+"#004225", "*"); settopleftpanel("#004225")
  }
  const BlackColorforTopLeft=()=>{
    console.log("BlackColorforTopLeft");
    app.contentWindow.postMessage("BlackColorforTopLeft : "+"#000000", "*"); settopleftpanel("#000000")
  }
  const WhiteColorforTopLeft=()=>{
    console.log("WhiteColorforTopLeft");
    app.contentWindow.postMessage("WhiteColorforTopLeft : "+"#fff", "*"); settopleftpanel("#fff")
  }
  const RedColorforTopLeft=()=>{
    console.log("RedColorforTopLeft");
    app.contentWindow.postMessage("RedColorforTopLeft : "+"#FF0000", "*"); settopleftpanel("#FF0000")
  }
  const LimeColorforTopLeft=()=>{
    console.log("LimeColorforTopLeft");
    app.contentWindow.postMessage("LimeColorforTopLeft : "+"#00FF00", "*"); settopleftpanel("#00FF00")
  }
  const YellowColorforTopLeft=()=>{
    console.log("YellowColorforTopLeft");
    app.contentWindow.postMessage("YellowColorforTopLeft : "+"#FFFF00", "*"); settopleftpanel("#FFFF00")
  }
  const BlueColorforTopLeft=()=>{
    console.log("BlueColorforTopLeft");
    app.contentWindow.postMessage("BlueColorforTopLeft : "+"#0000FF", "*"); settopleftpanel("#0000FF")
  }
  const OrangeColorforTopLeft=()=>{
    console.log("OrangeColorforTopLeft");
    app.contentWindow.postMessage("OrangeColorforTopLeft : "+"#FF6600", "*"); settopleftpanel("#FF6600")
  }
  const GoldColorforTopLeft=()=>{
    console.log("GoldColorforTopLeft");
    app.contentWindow.postMessage("GoldColorforTopLeft : "+"#FFD700", "*"); settopleftpanel("#FFD700")
  }
  const SilverColorforTopLeft=()=>{
    console.log("SilverColorforTopLeft");
    app.contentWindow.postMessage("SilverColorforTopLeft : "+"#BBC2CC", "*"); settopleftpanel("#BBC2CC")
  }
  const GMGColorforTopLeft=()=>{
    console.log("GMGColorforTopLeft");
    app.contentWindow.postMessage("GMGColorforTopLeft : "+"#5F6670", "*"); settopleftpanel("#5F6670")
  }
  // -----------------------------------------------------------------------------
  const BRGColorforBottomLeft=()=>{
    console.log("BRGColorforBottomLeft");
    app.contentWindow.postMessage("BRGColorforBottomLeft : "+"#004225", "*"); setbottomleftpanel("#004225")
  }
  const BlackColorforBottomLeft=()=>{
    console.log("BlackColorforBottomLeft");
    app.contentWindow.postMessage("BlackColorforBottomLeft : "+"#000000", "*"); setbottomleftpanel("#000000")
  }
  const WhiteColorforBottomLeft=()=>{
    console.log("WhiteColorforBottomLeft");
    app.contentWindow.postMessage("WhiteColorforBottomLeft : "+"#fff", "*"); setbottomleftpanel("#fff")
  }
  const RedColorforBottomLeft=()=>{
    console.log("RedColorforBottomLeft");
    app.contentWindow.postMessage("RedColorforBottomLeft : "+"#FF0000", "*"); setbottomleftpanel("#FF0000")
  }
  const LimeColorforBottomLeft=()=>{
    console.log("LimeColorforBottomLeft");
    app.contentWindow.postMessage("LimeColorforBottomLeft : "+"#00FF00", "*"); setbottomleftpanel("#00FF00")
  }
  const YellowColorforBottomLeft=()=>{
    console.log("YellowColorforBottomLeft");
    app.contentWindow.postMessage("YellowColorforBottomLeft : "+"#FFFF00", "*"); setbottomleftpanel("#FFFF00")
  }
  const BlueColorforBottomLeft=()=>{
    console.log("BlueColorforBottomLeft");
    app.contentWindow.postMessage("BlueColorforBottomLeft : "+"#0000FF", "*"); setbottomleftpanel("#0000FF")
  }
  const OrangeColorforBottomLeft=()=>{
    console.log("OrangeColorforBottomLeft");
    app.contentWindow.postMessage("OrangeColorforBottomLeft : "+"#FF6600", "*"); setbottomleftpanel("#FF6600")
  }
  const GoldColorforBottomLeft=()=>{
    console.log("GoldColorforBottomLeft");
    app.contentWindow.postMessage("GoldColorforBottomLeft : "+"#FFD700", "*"); setbottomleftpanel("#FFD700")
  }
  const SilverColorforBottomLeft=()=>{
    console.log("SilverColorforBottomLeft");
    app.contentWindow.postMessage("SilverColorforBottomLeft : "+"#BBC2CC", "*"); setbottomleftpanel("#BBC2CC")
  }
  const GMGColorforBottomLeft=()=>{
    console.log("GMGColorforBottomLeft");
    app.contentWindow.postMessage("GMGColorforBottomLeft : "+"#5F6670", "*"); setbottomleftpanel("#5F6670")
  }
  // ----------------------------------------------------------------------------
  const BRGColorforBottomRight=()=>{
    console.log("BRGColorforBottomRight");
    app.contentWindow.postMessage("BRGColorforBottomRight : "+"#004225", "*"); setbottomrightpanel("#004225")
  }
  const BlackColorforBottomRight=()=>{
    console.log("BlackColorforBottomRight");
    app.contentWindow.postMessage("BlackColorforBottomRight : "+"#000000", "*"); setbottomrightpanel("#000000")
  }
  const WhiteColorforBottomRight=()=>{
    console.log("WhiteColorforBottomRight");
    app.contentWindow.postMessage("WhiteColorforBottomRight : "+"#fff", "*"); setbottomrightpanel("#fff")
  }
  const RedColorforBottomRight=()=>{
    console.log("RedColorforBottomRight");
    app.contentWindow.postMessage("RedColorforBottomRight : "+"#FF0000", "*"); setbottomrightpanel("#FF0000")
  }
  const LimeColorforBottomRight=()=>{
    console.log("LimeColorforBottomRight");
    app.contentWindow.postMessage("LimeColorforBottomRight : "+"#00FF00", "*"); setbottomrightpanel("#00FF00")
  }
  const YellowColorforBottomRight=()=>{
    console.log("YellowColorforBottomRight");
    app.contentWindow.postMessage("YellowColorforBottomRight : "+"#FFFF00", "*"); setbottomrightpanel("#FFFF00")
  }
  const BlueColorforBottomRight=()=>{
    console.log("BlueColorforBottomRight");
    app.contentWindow.postMessage("BlueColorforBottomRight : "+"#0000FF", "*"); setbottomrightpanel("#0000FF")
  }
  const OrangeColorforBottomRight=()=>{
    console.log("OrangeColorforBottomRight");
    app.contentWindow.postMessage("OrangeColorforBottomRight : "+"#FF6600", "*"); setbottomrightpanel("#FF6600")
  }
  const GoldColorforBottomRight=()=>{
    console.log("GoldColorforBottomRight");
    app.contentWindow.postMessage("GoldColorforBottomRight : "+"#FFD700", "*"); setbottomrightpanel("#FFD700")
  }
  const SilverColorforBottomRight=()=>{
    console.log("SilverColorforBottomRight");
    app.contentWindow.postMessage("SilverColorforBottomRight : "+"#BBC2CC", "*"); setbottomrightpanel("#BBC2CC")
  }
  const GMGColorforBottomRight=()=>{
    console.log("GMGColorforBottomRight");
    app.contentWindow.postMessage("GMGColorforBottomRight : "+"#5F6670", "*"); setbottomrightpanel("#5F6670")
  }
  // }-------------------------------------------------------------------------
  const BRGColorforTopRight=()=>{
    console.log("BRGColorforTopRight");
    app.contentWindow.postMessage("BRGColorforTopRight : "+"#004225", "*"); settoprightpanel("#004225")
  }
  const BlackColorforTopRight=()=>{
    console.log("BlackColorforTopRight");
    app.contentWindow.postMessage("BlackColorforTopRight : "+"#000000", "*"); settoprightpanel("#000000")
  }
  const WhiteColorforTopRight=()=>{
    console.log("WhiteColorforTopRight");
    app.contentWindow.postMessage("WhiteColorforTopRight : "+"#fff", "*"); settoprightpanel("#fff")
  }
  const RedColorforTopRight=()=>{
    console.log("RedColorforTopRight");
    app.contentWindow.postMessage("RedColorforTopRight : "+"#FF0000", "*"); settoprightpanel("#FF0000")
  }
  const LimeColorforTopRight=()=>{
    console.log("LimeColorforTopRight");
    app.contentWindow.postMessage("LimeColorforTopRight : "+"#00FF00", "*"); settoprightpanel("#00FF00")
  }
  const YellowColorforTopRight=()=>{
    console.log("YellowColorforTopRight");
    app.contentWindow.postMessage("YellowColorforTopRight : "+"#FFFF00", "*"); settoprightpanel("#FFFF00")
  }
  const BlueColorforTopRight=()=>{
    console.log("BlueColorforTopRight");
    app.contentWindow.postMessage("BlueColorforTopRight : "+"#0000FF", "*"); settoprightpanel("#0000FF")
  }
  const OrangeColorforTopRight=()=>{
    console.log("OrangeColorforTopRight");
    app.contentWindow.postMessage("OrangeColorforTopRight : "+"#FF6600", "*"); settoprightpanel("#FF6600")
  }
  const GoldColorforTopRight=()=>{
    console.log("GoldColorforTopRight");
    app.contentWindow.postMessage("GoldColorforTopRight : "+"#FFD700", "*"); settoprightpanel("#FFD700")
  }
  const SilverColorforTopRight=()=>{
    console.log("SilverColorforTopRight");
    app.contentWindow.postMessage("SilverColorforTopRight : "+"#BBC2CC", "*"); settoprightpanel("#BBC2CC")
  }
  const GMGColorforTopRight=()=>{
    console.log("GMGColorforTopRight");
    app.contentWindow.postMessage("GMGColorforTopRight : "+"#5F6670", "*"); settoprightpanel("#5F6670")
  }
  // ---------------------------------------------------------------------------------
  const BRGColorforMonitorMount=()=>{
    console.log("BRGColorforMonitorMount");
    app.contentWindow.postMessage("BRGColorforMonitorMount : "+"#004225", "*"); setmonitorMount("#004225")
  }
  const BlackColorforMonitorMount=()=>{
    console.log("BlackColorforMonitorMount");
    app.contentWindow.postMessage("BlackColorforMonitorMount : "+"#000000", "*"); setmonitorMount("#000000")
  }
  const WhiteColorforMonitorMount=()=>{
    console.log("WhiteColorforMonitorMount");
    app.contentWindow.postMessage("WhiteColorforMonitorMount : "+"#fff", "*"); setmonitorMount("#fff")
  }
  const RedColorforMonitorMount=()=>{
    console.log("RedColorforMonitorMount");
    app.contentWindow.postMessage("RedColorforMonitorMount : "+"#FF0000", "*"); setmonitorMount("#FF0000")
  }
  const LimeColorforMonitorMount=()=>{
    console.log("LimeColorforMonitorMount");
    app.contentWindow.postMessage("LimeColorforMonitorMount : "+"#00FF00", "*"); setmonitorMount("#00FF00")
  }
  const YellowColorforMonitorMount=()=>{
    console.log("YellowColorforMonitorMount");
    app.contentWindow.postMessage("YellowColorforMonitorMount : "+"#FFFF00", "*"); setmonitorMount("#FFFF00")
  }
  const BlueColorforMonitorMount=()=>{
    console.log("BlueColorforMonitorMount");
    app.contentWindow.postMessage("BlueColorforMonitorMount : "+"#0000FF", "*"); setmonitorMount("#0000FF")
  }
  const OrangeColorforMonitorMount=()=>{
    console.log("OrangeColorforMonitorMount");
    app.contentWindow.postMessage("OrangeColorforMonitorMount : "+"#FF6600", "*"); setmonitorMount("#FF6600")
  }
  const GoldColorforMonitorMount=()=>{
    console.log("GoldColorforMonitorMount");
    app.contentWindow.postMessage("GoldColorforMonitorMount : "+"#FFD700", "*"); setmonitorMount("#FFD700")
  }
  const SilverColorforMonitorMount=()=>{
    console.log("SilverColorforMonitorMount");
    app.contentWindow.postMessage("SilverColorforMonitorMount : "+"#BBC2CC", "*"); setmonitorMount("#BBC2CC")
  }
  const GMGColorforMonitorMount=()=>{
    console.log("GMGColorforMonitorMount");
    app.contentWindow.postMessage("GMGColorforMonitorMount : "+"#5F6670", "*"); setmonitorMount("#5F6670")
  }
  // -------------------------------------------------------------------------------
  const BRGColorforShirtFabric=()=>{
    console.log("BRGColorforShirtFabric");
    app.contentWindow.postMessage("BRGColorforShirtFabric : "+"#004225", "*"); setShirtFabric("#004225")
  }
  const BlackColorforShirtFabric=()=>{
    console.log("BlackColorforShirtFabric");
    app.contentWindow.postMessage("BlackColorforShirtFabric : "+"#000000", "*"); setShirtFabric("#000000")
  }
  const WhiteColorforShirtFabric=()=>{
    console.log("WhiteColorforShirtFabric");
    app.contentWindow.postMessage("WhiteColorforShirtFabric : "+"#fff", "*"); setShirtFabric("#fff")
  }
  const RedColorforShirtFabric=()=>{
    console.log("RedColorforShirtFabric");
    app.contentWindow.postMessage("RedColorforShirtFabric : "+"#FF0000", "*"); setShirtFabric("#FF0000")
  }
  const LimeColorforShirtFabric=()=>{
    console.log("LimeColorforShirtFabric");
    app.contentWindow.postMessage("LimeColorforShirtFabric : "+"#00FF00", "*"); setShirtFabric("#00FF00")
  }
  const YellowColorforShirtFabric=()=>{
    console.log("YellowColorforShirtFabric");
    app.contentWindow.postMessage("YellowColorforShirtFabric : "+"#FFFF00", "*"); setShirtFabric("#FFFF00")
  }
  const BlueColorforShirtFabric=()=>{
    console.log("BlueColorforShirtFabric");
    app.contentWindow.postMessage("BlueColorforShirtFabric : "+"#0000FF", "*"); setShirtFabric("#0000FF")
  }
  const OrangeColorforShirtFabric=()=>{
    console.log("OrangeColorforShirtFabric");
    app.contentWindow.postMessage("OrangeColorforShirtFabric : "+"#FF6600", "*"); setShirtFabric("#FF6600")
  }
  const GoldColorforShirtFabric=()=>{
    console.log("GoldColorforShirtFabric");
    app.contentWindow.postMessage("GoldColorforShirtFabric : "+"#FFD700", "*"); setShirtFabric("#FFD700")
  }
  const SilverColorforShirtFabric=()=>{
    console.log("SilverColorforShirtFabric");
    app.contentWindow.postMessage("SilverColorforShirtFabric : "+"#BBC2CC", "*"); setShirtFabric("#BBC2CC")
  }
  const GMGColorforShirtFabric=()=>{
    console.log("GMGColorforShirtFabric");
    app.contentWindow.postMessage("GMGColorforShirtFabric : "+"#5F6670", "*"); setShirtFabric("#5F6670")
  }
  // ---------------------------------------------------------------------------
  // -------------------------------------------------------------------------------
  const BRGColorforFrontDust=()=>{
    console.log("BRGColorforFrontDust");
    app.contentWindow.postMessage("BRGColorforFrontDust : "+"#004225", "*"); setFrontDust("#004225")
  }
  const BlackColorforFrontDust=()=>{
    console.log("BlackColorforFrontDust");
    app.contentWindow.postMessage("BlackColorforFrontDust : "+"#000000", "*"); setFrontDust("#000000")
  }
  const WhiteColorforFrontDust=()=>{
    console.log("WhiteColorforFrontDust");
    app.contentWindow.postMessage("WhiteColorforFrontDust : "+"#fff", "*"); setFrontDust("#fff")
  }
  const RedColorforFrontDust=()=>{
    console.log("RedColorforFrontDust");
    app.contentWindow.postMessage("RedColorforFrontDust : "+"#FF0000", "*"); setFrontDust("#FF0000")
  }
  const LimeColorforFrontDust=()=>{
    console.log("LimeColorforFrontDust");
    app.contentWindow.postMessage("LimeColorforFrontDust : "+"#00FF00", "*"); setFrontDust("#00FF00")
  }
  const YellowColorforFrontDust=()=>{
    console.log("YellowColorforFrontDust");
    app.contentWindow.postMessage("YellowColorforFrontDust : "+"#FFFF00", "*"); setFrontDust("#FFFF00")
  }
  const BlueColorforFrontDust=()=>{
    console.log("BlueColorforFrontDust");
    app.contentWindow.postMessage("BlueColorforFrontDust : "+"#0000FF", "*"); setFrontDust("#0000FF")
  }
  const OrangeColorforFrontDust=()=>{
    console.log("OrangeColorforFrontDust");
    app.contentWindow.postMessage("OrangeColorforFrontDust : "+"#FF6600", "*"); setFrontDust("#FF6600")
  }
  const GoldColorforFrontDust=()=>{
    console.log("GoldColorforFrontDust");
    app.contentWindow.postMessage("GoldColorforFrontDust : "+"#FFD700", "*"); setFrontDust("#FFD700")
  }
  const SilverColorforFrontDust=()=>{
    console.log("SilverColorforFrontDust");
    app.contentWindow.postMessage("SilverColorforFrontDust : "+"#BBC2CC", "*"); setFrontDust("#BBC2CC")
  }
  const GMGColorforFrontDust=()=>{
    console.log("GMGColorforFrontDust");
    app.contentWindow.postMessage("GMGColorforFrontDust : "+"#5F6670", "*"); setFrontDust("#5F6670")
  }
  // ---------------------------------------------------------------------------
  // -------------------------------------------------------------------------------
  const BRGColorforRightDust=()=>{
    console.log("BRGColorforRightDust");
    app.contentWindow.postMessage("BRGColorforRightDust : "+"#004225", "*"); setRightDust("#004225")
  }
  const BlackColorforRightDust=()=>{
    console.log("BlackColorforRightDust");
    app.contentWindow.postMessage("BlackColorforRightDust : "+"#000000", "*"); setRightDust("#000000")
  }
  const WhiteColorforRightDust=()=>{
    console.log("WhiteColorforRightDust");
    app.contentWindow.postMessage("WhiteColorforRightDust : "+"#fff", "*"); setRightDust("#fff")
  }
  const RedColorforRightDust=()=>{
    console.log("RedColorforRightDust");
    app.contentWindow.postMessage("RedColorforRightDust : "+"#FF0000", "*"); setRightDust("#FF0000")
  }
  const LimeColorforRightDust=()=>{
    console.log("LimeColorforRightDust");
    app.contentWindow.postMessage("LimeColorforRightDust : "+"#00FF00", "*"); setRightDust("#00FF00")
  }
  const YellowColorforRightDust=()=>{
    console.log("YellowColorforRightDust");
    app.contentWindow.postMessage("YellowColorforRightDust : "+"#FFFF00", "*"); setRightDust("#FFFF00")
  }
  const BlueColorforRightDust=()=>{
    console.log("BlueColorforRightDust");
    app.contentWindow.postMessage("BlueColorforRightDust : "+"#0000FF", "*"); setRightDust("#0000FF")
  }
  const OrangeColorforRightDust=()=>{
    console.log("OrangeColorforRightDust");
    app.contentWindow.postMessage("OrangeColorforRightDust : "+"#FF6600", "*"); setRightDust("#FF6600")
  }
  const GoldColorforRightDust=()=>{
    console.log("GoldColorforRightDust");
    app.contentWindow.postMessage("GoldColorforRightDust : "+"#FFD700", "*"); setRightDust("#FFD700")
  }
  const SilverColorforRightDust=()=>{
    console.log("SilverColorforRightDust");
    app.contentWindow.postMessage("SilverColorforRightDust : "+"#BBC2CC", "*"); setRightDust("#BBC2CC")
  }
  const GMGColorforRightDust=()=>{
    console.log("GMGColorforRightDust");
    app.contentWindow.postMessage("GMGColorforRightDust : "+"#5F6670", "*"); setRightDust("#5F6670")
  }
  // ---------------------------------------------------------------------------
  // -------------------------------------------------------------------------------
  const BRGColorforLeftDust=()=>{
    console.log("BRGColorforLeftDust");
    app.contentWindow.postMessage("BRGColorforLeftDust : "+"#004225", "*"); setLeftDust("#004225")
  }
  const BlackColorforLeftDust=()=>{
    console.log("BlackColorforLeftDust");
    app.contentWindow.postMessage("BlackColorforLeftDust : "+"#000000", "*"); setLeftDust("#000000")
  }
  const WhiteColorforLeftDust=()=>{
    console.log("WhiteColorforLeftDust");
    app.contentWindow.postMessage("WhiteColorforLeftDust : "+"#fff", "*"); setLeftDust("#fff")
  }
  const RedColorforLeftDust=()=>{
    console.log("RedColorforLeftDust");
    app.contentWindow.postMessage("RedColorforLeftDust : "+"#FF0000", "*"); setLeftDust("#FF0000")
  }
  const LimeColorforLeftDust=()=>{
    console.log("LimeColorforLeftDust");
    app.contentWindow.postMessage("LimeColorforLeftDust : "+"#00FF00", "*"); setLeftDust("#00FF00")
  }
  const YellowColorforLeftDust=()=>{
    console.log("YellowColorforLeftDust");
    app.contentWindow.postMessage("YellowColorforLeftDust : "+"#FFFF00", "*"); setLeftDust("#FFFF00")
  }
  const BlueColorforLeftDust=()=>{
    console.log("BlueColorforLeftDust");
    app.contentWindow.postMessage("BlueColorforLeftDust : "+"#0000FF", "*"); setLeftDust("#0000FF")
  }
  const OrangeColorforLeftDust=()=>{
    console.log("OrangeColorforLeftDust");
    app.contentWindow.postMessage("OrangeColorforLeftDust : "+"#FF6600", "*"); setLeftDust("#FF6600")
  }
  const GoldColorforLeftDust=()=>{
    console.log("GoldColorforLeftDust");
    app.contentWindow.postMessage("GoldColorforLeftDust : "+"#FFD700", "*"); setLeftDust("#FFD700")
  }
  const SilverColorforLeftDust=()=>{
    console.log("SilverColorforLeftDust");
    app.contentWindow.postMessage("SilverColorforLeftDust : "+"#BBC2CC", "*"); setLeftDust("#BBC2CC")
  }
  const GMGColorforLeftDust=()=>{
    console.log("GMGColorforLeftDust");
    app.contentWindow.postMessage("GMGColorforLeftDust : "+"#5F6670", "*"); setLeftDust("#5F6670")
  }
  // ---------------------------------------------------------------------------
  return (
    <>
        <div id="Color">
            {/* <Modal show={showSeatFabric} onHide={handleSeatFabricClose}>
              <Modal.Header closeButton>
                <Modal.Title>Modal heading</Modal.Title>
              </Modal.Header>
              <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleSeatFabricClose}>
                  Close
                </Button>
                <Button variant="primary" onClick={handleSeatFabricClose}>
                  Save Changes
                </Button>
              </Modal.Footer>
            </Modal> */}
            <div className='row'>
                <p>Change the Seat Fabric Color</p>
                <div onClick={()=>BRGColorforSeat()} className='color British_Racing_Green'></div>
                <div onClick={()=>BlackColorforSeat()} className='color Black'></div>
                <div onClick={()=>WhiteColorforSeat()} className='color White'></div>
                <div onClick={()=>RedColorforSeat()} className='color Red'></div>
                <div onClick={()=>LimeColorforSeat()}className='color Lime'></div>
                <div onClick={()=>YellowColorforSeat()} className='color Yellow'></div>
                <div onClick={()=>BlueColorforSeat()} className='color Blue'></div>
                <div onClick={()=>OrangeColorforSeat()} className='color Orange'></div>
                <div onClick={()=>GoldColorforSeat()} className='color Gold'></div>
                <div onClick={()=>SilverColorforSeat()} className='color Silver'></div>
                <div onClick={()=>GMGColorforSeat()} className='color Gun_Metal_Grey'></div>
                <center><p>Add Custom Color</p></center>
                <input onInput={(e)=>{
                  setseatfabric(e.target.value);
                  CustomColorSeatFabric(e.target.value);
                }} onKeyDown={(e)=>{
                  setseatfabric(e.target.value);
                  CustomColorSeatFabric(e.target.value);
                }} type="color"  className='extramargin'/>
                {/* <p>Change the Color of the 4 Side Panels</p>
                <div onClick={()=>BRGColorforPanels()} className='color British_Racing_Green'></div>
                <div onClick={()=>BlackColorforPanels()} className='color Black'></div>
                <div onClick={()=>WhiteColorforPanels()} className='color White'></div>
                <div onClick={()=>RedColorforPanels()} className='color Red'></div>
                <div onClick={()=>LimeColorforPanels()}className='color Lime'></div>
                <div onClick={()=>YellowColorforPanels()} className='color Yellow'></div>
                <div onClick={()=>BlueColorforPanels()} className='color Blue'></div>
                <div onClick={()=>OrangeColorforPanels()} className='color Orange'></div>
                <div onClick={()=>GoldColorforPanels()} className='color Gold'></div>
                <div onClick={()=>SilverColorforPanels()} className='color Silver'></div>
                <div onClick={()=>GMGColorforPanels()} className='color Gun_Metal_Grey'></div>
                <center><p>Add Custom Color</p></center>
                <input type="color" className='extramargin'/> */}
                <p>Change the Color of the back of the Monitor</p>
                <div onClick={()=>BRGColorforMonitor()} className='color British_Racing_Green'></div>
                <div onClick={()=>BlackColorforMonitor()} className='color Black'></div>
                <div onClick={()=>WhiteColorforMonitor()} className='color White'></div>
                <div onClick={()=>RedColorforMonitor()} className='color Red'></div>
                <div onClick={()=>LimeColorforMonitor()}className='color Lime'></div>
                <div onClick={()=>YellowColorforMonitor()} className='color Yellow'></div>
                <div onClick={()=>BlueColorforMonitor()} className='color Blue'></div>
                <div onClick={()=>OrangeColorforMonitor()} className='color Orange'></div>
                <div onClick={()=>GoldColorforMonitor()} className='color Gold'></div>
                <div onClick={()=>SilverColorforMonitor()} className='color Silver'></div>
                <div onClick={()=>GMGColorforMonitor()} className='color Gun_Metal_Grey'></div>
                <center><p>Add Custom Color</p></center>
                <input type="color" onChange={(e)=>{
                  
                  setmonitor(e.target.value);
                  CustomColorMonitor(e.target.value);
                }} className='extramargin'/>
                <p>Change the Color of the RES Logo</p>
                <div onClick={()=>BRGColorforRESLogo()} className='color British_Racing_Green'></div>
                <div onClick={()=>BlackColorforRESLogo()} className='color Black'></div>
                <div onClick={()=>WhiteColorforRESLogo()} className='color White'></div>
                <div onClick={()=>RedColorforRESLogo()} className='color Red'></div>
                <div onClick={()=>LimeColorforRESLogo()}className='color Lime'></div>
                <div onClick={()=>YellowColorforRESLogo()} className='color Yellow'></div>
                <div onClick={()=>BlueColorforRESLogo()} className='color Blue'></div>
                <div onClick={()=>OrangeColorforRESLogo()} className='color Orange'></div>
                <div onClick={()=>GoldColorforRESLogo()} className='color Gold'></div>
                <div onClick={()=>SilverColorforRESLogo()} className='color Silver'></div>
                <div onClick={()=>GMGColorforRESLogo()} className='color Gun_Metal_Grey'></div>
                <center><p>Add Custom Color</p></center>
                <input type="color" onChange={(e)=>{
                  setresLogo(e.target.value);
                  CustomColorResLogo(e.target.value);
                
                }} className='extramargin'/>
                <p>Change Pedal Mount Color</p>
                <div onClick={()=>BRGColorforMountColor()} className='color British_Racing_Green'></div>
                <div onClick={()=>BlackColorforMountColor()} className='color Black'></div>
                <div onClick={()=>WhiteColorforMountColor()} className='color White'></div>
                <div onClick={()=>RedColorforMountColor()} className='color Red'></div>
                <div onClick={()=>LimeColorforMountColor()}className='color Lime'></div>
                <div onClick={()=>YellowColorforMountColor()} className='color Yellow'></div>
                <div onClick={()=>BlueColorforMountColor()} className='color Blue'></div>
                <div onClick={()=>OrangeColorforMountColor()} className='color Orange'></div>
                <div onClick={()=>GoldColorforMountColor()} className='color Gold'></div>
                <div onClick={()=>SilverColorforMountColor()} className='color Silver'></div>
                <div onClick={()=>GMGColorforMountColor()} className='color Gun_Metal_Grey'></div>
                <center><p>Add Custom Color</p></center>
                <input type="color" onChange={(e)=>{
                  setpedalMount(e.target.value);
                  CustomColorPedalMount(e.target.value);
                
                }} className='extramargin'/>
                <p>Change Inside Frame Color</p>
                <div onClick={()=>BRGColorforFrame()} className='color British_Racing_Green'></div>
                <div onClick={()=>BlackColorforFrame()} className='color Black'></div>
                <div onClick={()=>WhiteColorforFrame()} className='color White'></div>
                <div onClick={()=>RedColorforFrame()} className='color Red'></div>
                <div onClick={()=>LimeColorforFrame()}className='color Lime'></div>
                <div onClick={()=>YellowColorforFrame()} className='color Yellow'></div>
                <div onClick={()=>BlueColorforFrame()} className='color Blue'></div>
                <div onClick={()=>OrangeColorforFrame()} className='color Orange'></div>
                <div onClick={()=>GoldColorforFrame()} className='color Gold'></div>
                <div onClick={()=>SilverColorforFrame()} className='color Silver'></div>
                <div onClick={()=>GMGColorforFrame()} className='color Gun_Metal_Grey'></div>
                <center><p>Add Custom Color</p></center>
                <input onChange={(e)=>{
                  setinsideFrame(e.target.value);
                  CustomColorInsideFrame(e.target.value);
                
                }} type="color" className='extramargin'/>
                <p>Change Side Seat Mount Colors</p>
                <div onClick={()=>BRGColorforSeatMounts()} className='color British_Racing_Green'></div>
                <div onClick={()=>BlackColorforSeatMounts()} className='color Black'></div>
                <div onClick={()=>WhiteColorforSeatMounts()} className='color White'></div>
                <div onClick={()=>RedColorforSeatMounts()} className='color Red'></div>
                <div onClick={()=>LimeColorforSeatMounts()}className='color Lime'></div>
                <div onClick={()=>YellowColorforSeatMounts()} className='color Yellow'></div>
                <div onClick={()=>BlueColorforSeatMounts()} className='color Blue'></div>
                <div onClick={()=>OrangeColorforSeatMounts()} className='color Orange'></div>
                <div onClick={()=>GoldColorforSeatMounts()} className='color Gold'></div>
                <div onClick={()=>SilverColorforSeatMounts()} className='color Silver'></div>
                <div onClick={()=>GMGColorforSeatMounts()} className='color Gun_Metal_Grey'></div>
                <center><p>Add Custom Color</p></center>
                <input onChange={(e)=>{
                  setsideseatMount(e.target.value);
                  CustomColorsideSeatMount(e.target.value);
                
                }} type="color" className='extramargin'/>
                {/* <p>Change Color of the Steering Wheel Grips</p>
                <div onClick={()=>BRGColorforSteeringWheel()} className='color British_Racing_Green'></div>
                <div onClick={()=>BlackColorforSteeringWheel()} className='color Black'></div>
                <div onClick={()=>WhiteColorforSteeringWheel()} className='color White'></div>
                <div onClick={()=>RedColorforSteeringWheel()} className='color Red'></div>
                <div onClick={()=>LimeColorforSteeringWheel()}className='color Lime'></div>
                <div onClick={()=>YellowColorforSteeringWheel()} className='color Yellow'></div>
                <div onClick={()=>BlueColorforSteeringWheel()} className='color Blue'></div>
                <div onClick={()=>OrangeColorforSteeringWheel()} className='color Orange'></div>
                <div onClick={()=>GoldColorforSteeringWheel()} className='color Gold'></div>
                <div onClick={()=>SilverColorforSteeringWheel()} className='color Silver'></div>
                <div onClick={()=>GMGColorforSteeringWheel()} className='color Gun_Metal_Grey'></div>
                <center><p>Add Custom Color</p></center>
                <input onChange={(e)=>{
                  setsteeringWheels(e.target.value);
                  CustomColorSteeringWheels()
                
                }} type="color" className='extramargin'/> */}
                <p>Change Color of the Main Frame</p>
                <div onClick={()=>BRGColorforMainFrame()} className='color British_Racing_Green'></div>
                <div onClick={()=>BlackColorforMainFrame()} className='color Black'></div>
                <div onClick={()=>WhiteColorforMainFrame()} className='color White'></div>
                <div onClick={()=>RedColorforMainFrame()} className='color Red'></div>
                <div onClick={()=>LimeColorforMainFrame()}className='color Lime'></div>
                <div onClick={()=>YellowColorforMainFrame()} className='color Yellow'></div>
                <div onClick={()=>BlueColorforMainFrame()} className='color Blue'></div>
                <div onClick={()=>OrangeColorforMainFrame()} className='color Orange'></div>
                <div onClick={()=>GoldColorforMainFrame()} className='color Gold'></div>
                <div onClick={()=>SilverColorforMainFrame()} className='color Silver'></div>
                <div onClick={()=>GMGColorforMainFrame()} className='color Gun_Metal_Grey'></div>
                <center><p>Add Custom Color</p></center>
                <input onChange={(e)=>{
                  setmainFrame(e.target.value);
                  CustomColorMainFrame(e.target.value);
                
                }} type="color" className='extramargin'/>
                <p>Change Color of the Floor</p>
                <div onClick={()=>BRGColorforFloor()} className='color British_Racing_Green'></div>
                <div onClick={()=>BlackColorforFloor()} className='color Black'></div>
                <div onClick={()=>WhiteColorforFloor()} className='color White'></div>
                <div onClick={()=>RedColorforFloor()} className='color Red'></div>
                <div onClick={()=>LimeColorforFloor()}className='color Lime'></div>
                <div onClick={()=>YellowColorforFloor()} className='color Yellow'></div>
                <div onClick={()=>BlueColorforFloor()} className='color Blue'></div>
                <div onClick={()=>OrangeColorforFloor()} className='color Orange'></div>
                <div onClick={()=>GoldColorforFloor()} className='color Gold'></div>
                <div onClick={()=>SilverColorforFloor()} className='color Silver'></div>
                <div onClick={()=>GMGColorforFloor()} className='color Gun_Metal_Grey'></div>
                <center><p>Add Custom Color</p></center>
                <input onChange={(e)=>{
                  setfloor(e.target.value);
                  CustomColorFloor(e.target.value)
                
                }} type="color" className='extramargin'/>
                <p>Change Color of the Steering Column</p>
                <div onClick={()=>BRGColorforSteeringColumn()} className='color British_Racing_Green'></div>
                <div onClick={()=>BlackColorforSteeringColumn()} className='color Black'></div>
                <div onClick={()=>WhiteColorforSteeringColumn()} className='color White'></div>
                <div onClick={()=>RedColorforSteeringColumn()} className='color Red'></div>
                <div onClick={()=>LimeColorforSteeringColumn()}className='color Lime'></div>
                <div onClick={()=>YellowColorforSteeringColumn()} className='color Yellow'></div>
                <div onClick={()=>BlueColorforSteeringColumn()} className='color Blue'></div>
                <div onClick={()=>OrangeColorforSteeringColumn()} className='color Orange'></div>
                <div onClick={()=>GoldColorforSteeringColumn()} className='color Gold'></div>
                <div onClick={()=>SilverColorforSteeringColumn()} className='color Silver'></div>
                <div onClick={()=>GMGColorforSteeringColumn()} className='color Gun_Metal_Grey'></div>
                <center><p>Add Custom Color</p></center>
                <input onChange={(e)=>{
                  setsteeringColumn(e.target.value);
                  CustomColorSteeringColumn(e.target.value)
                
                }} type="color" className='extramargin'/>
                <p>Change Color of the Monitor Mount</p>
                <div onClick={()=>BRGColorforMonitorMount()} className='color British_Racing_Green'></div>
                <div onClick={()=>BlackColorforMonitorMount()} className='color Black'></div>
                <div onClick={()=>WhiteColorforMonitorMount()} className='color White'></div>
                <div onClick={()=>RedColorforMonitorMount()} className='color Red'></div>
                <div onClick={()=>LimeColorforMonitorMount()}className='color Lime'></div>
                <div onClick={()=>YellowColorforMonitorMount()} className='color Yellow'></div>
                <div onClick={()=>BlueColorforMonitorMount()} className='color Blue'></div>
                <div onClick={()=>OrangeColorforMonitorMount()} className='color Orange'></div>
                <div onClick={()=>GoldColorforMonitorMount()} className='color Gold'></div>
                <div onClick={()=>SilverColorforMonitorMount()} className='color Silver'></div>
                <div onClick={()=>GMGColorforMonitorMount()} className='color Gun_Metal_Grey'></div>
                <center><p>Add Custom Color</p></center>
                <input onChange={(e)=>{
                  setmonitorMount(e.target.value)
                  CustomColorMonitorMount(e.target.value)
                
                }} type="color" className='extramargin'/>
                <p>Change Color of Speakers</p>
                <div onClick={()=>BRGColorforSpeakers()} className='color British_Racing_Green'></div>
                <div onClick={()=>BlackColorforSpeakers()} className='color Black'></div>
                <div onClick={()=>WhiteColorforSpeakers()} className='color White'></div>
                <div onClick={()=>RedColorforSpeakers()} className='color Red'></div>
                <div onClick={()=>LimeColorforSpeakers()}className='color Lime'></div>
                <div onClick={()=>YellowColorforSpeakers()} className='color Yellow'></div>
                <div onClick={()=>BlueColorforSpeakers()} className='color Blue'></div>
                <div onClick={()=>OrangeColorforSpeakers()} className='color Orange'></div>
                <div onClick={()=>GoldColorforSpeakers()} className='color Gold'></div>
                <div onClick={()=>SilverColorforSpeakers()} className='color Silver'></div>
                <div onClick={()=>GMGColorforSpeakers()} className='color Gun_Metal_Grey'></div>
                <center><p>Add Custom Color</p></center>
                <input onChange={(e)=>{
                  setSpeaker(e.target.value)
                  CustomColorSpeaker(e.target.value)
                
                }} type="color" className='extramargin'/>
                <p>Change Color of Top Right Panel</p>
                <div onClick={()=>BRGColorforTopRight()} className='color British_Racing_Green'></div>
                <div onClick={()=>BlackColorforTopRight()} className='color Black'></div>
                <div onClick={()=>WhiteColorforTopRight()} className='color White'></div>
                <div onClick={()=>RedColorforTopRight()} className='color Red'></div>
                <div onClick={()=>LimeColorforTopRight()}className='color Lime'></div>
                <div onClick={()=>YellowColorforTopRight()} className='color Yellow'></div>
                <div onClick={()=>BlueColorforTopRight()} className='color Blue'></div>
                <div onClick={()=>OrangeColorforTopRight()} className='color Orange'></div>
                <div onClick={()=>GoldColorforTopRight()} className='color Gold'></div>
                <div onClick={()=>SilverColorforTopRight()} className='color Silver'></div>
                <div onClick={()=>GMGColorforTopRight()} className='color Gun_Metal_Grey'></div>
                <center><p>Add Custom Color</p></center>
                <input onChange={(e)=>{
                  settoprightpanel(e.target.value)
                  CustomColorToprightPanel(e.target.value)
                
                }} type="color" className='extramargin'/>
                <p>Change Color of Bottom Right Panel</p>
                <div onClick={()=>BRGColorforBottomRight()} className='color British_Racing_Green'></div>
                <div onClick={()=>BlackColorforBottomRight()} className='color Black'></div>
                <div onClick={()=>WhiteColorforBottomRight()} className='color White'></div>
                <div onClick={()=>RedColorforBottomRight()} className='color Red'></div>
                <div onClick={()=>LimeColorforBottomRight()}className='color Lime'></div>
                <div onClick={()=>YellowColorforBottomRight()} className='color Yellow'></div>
                <div onClick={()=>BlueColorforBottomRight()} className='color Blue'></div>
                <div onClick={()=>OrangeColorforBottomRight()} className='color Orange'></div>
                <div onClick={()=>GoldColorforBottomRight()} className='color Gold'></div>
                <div onClick={()=>SilverColorforBottomRight()} className='color Silver'></div>
                <div onClick={()=>GMGColorforBottomRight()} className='color Gun_Metal_Grey'></div>
                <center><p>Add Custom Color</p></center>
                <input onChange={(e)=>{
                  setbottomrightpanel(e.target.value)
                  CustomColorbottomrightPanel(e.target.value);
                }} type="color" className='extramargin'/>
                <p>Change Color of Top Left Panel</p>
                <div onClick={()=>BRGColorforTopLeft()} className='color British_Racing_Green'></div>
                <div onClick={()=>BlackColorforTopLeft()} className='color Black'></div>
                <div onClick={()=>WhiteColorforTopLeft()} className='color White'></div>
                <div onClick={()=>RedColorforTopLeft()} className='color Red'></div>
                <div onClick={()=>LimeColorforTopLeft()}className='color Lime'></div>
                <div onClick={()=>YellowColorforTopLeft()} className='color Yellow'></div>
                <div onClick={()=>BlueColorforTopLeft()} className='color Blue'></div>
                <div onClick={()=>OrangeColorforTopLeft()} className='color Orange'></div>
                <div onClick={()=>GoldColorforTopLeft()} className='color Gold'></div>
                <div onClick={()=>SilverColorforTopLeft()} className='color Silver'></div>
                <div onClick={()=>GMGColorforTopLeft()} className='color Gun_Metal_Grey'></div>
                <center><p>Add Custom Color</p></center>
                <input onChange={(e)=>{
                  settopleftpanel(e.target.value);
                  CustomColorTopleftPanel(e.target.value)
                
                }} type="color" className='extramargin'/>
                <p>Change Color of Bottom Left Panel</p>
                <div onClick={()=>BRGColorforBottomLeft()} className='color British_Racing_Green'></div>
                <div onClick={()=>BlackColorforBottomLeft()} className='color Black'></div>
                <div onClick={()=>WhiteColorforBottomLeft()} className='color White'></div>
                <div onClick={()=>RedColorforBottomLeft()} className='color Red'></div>
                <div onClick={()=>LimeColorforBottomLeft()}className='color Lime'></div>
                <div onClick={()=>YellowColorforBottomLeft()} className='color Yellow'></div>
                <div onClick={()=>BlueColorforBottomLeft()} className='color Blue'></div>
                <div onClick={()=>OrangeColorforBottomLeft()} className='color Orange'></div>
                <div onClick={()=>GoldColorforBottomLeft()} className='color Gold'></div>
                <div onClick={()=>SilverColorforBottomLeft()} className='color Silver'></div>
                <div onClick={()=>GMGColorforBottomLeft()} className='color Gun_Metal_Grey'></div>
                <center><p>Add Custom Color</p></center>
                <input onChange={(e)=>{
                  setbottomleftpanel(e.target.value)
                  CustomColorbottomleftPanel(e.target.value)
                
                }} type="color" className='extramargin'/>
                <p>Change Color of  Fabric Shirt</p>
                <div onClick={()=>BRGColorforShirtFabric()} className='color British_Racing_Green'></div>
                <div onClick={()=>BlackColorforShirtFabric()} className='color Black'></div>
                <div onClick={()=>WhiteColorforShirtFabric()} className='color White'></div>
                <div onClick={()=>RedColorforShirtFabric()} className='color Red'></div>
                <div onClick={()=>LimeColorforShirtFabric()}className='color Lime'></div>
                <div onClick={()=>YellowColorforShirtFabric()} className='color Yellow'></div>
                <div onClick={()=>BlueColorforShirtFabric()} className='color Blue'></div>
                <div onClick={()=>OrangeColorforShirtFabric()} className='color Orange'></div>
                <div onClick={()=>GoldColorforShirtFabric()} className='color Gold'></div>
                <div onClick={()=>SilverColorforShirtFabric()} className='color Silver'></div>
                <div onClick={()=>GMGColorforShirtFabric()} className='color Gun_Metal_Grey'></div>
                <center><p>Add Custom Color</p></center>
                <input onChange={(e)=>{
                  setShirtFabric(e.target.value)
                  CustomColorShirtFabric(e.target.value)
                
                }} type="color" className='extramargin'/>
                <p>Change Color of Front Magnetic Dust Cover</p>
                <div onClick={()=>BRGColorforFrontDust()} className='color British_Racing_Green'></div>
                <div onClick={()=>BlackColorforFrontDust()} className='color Black'></div>
                <div onClick={()=>WhiteColorforFrontDust()} className='color White'></div>
                <div onClick={()=>RedColorforFrontDust()} className='color Red'></div>
                <div onClick={()=>LimeColorforFrontDust()}className='color Lime'></div>
                <div onClick={()=>YellowColorforFrontDust()} className='color Yellow'></div>
                <div onClick={()=>BlueColorforFrontDust()} className='color Blue'></div>
                <div onClick={()=>OrangeColorforFrontDust()} className='color Orange'></div>
                <div onClick={()=>GoldColorforFrontDust()} className='color Gold'></div>
                <div onClick={()=>SilverColorforFrontDust()} className='color Silver'></div>
                <div onClick={()=>GMGColorforFrontDust()} className='color Gun_Metal_Grey'></div>
                <center><p>Add Custom Color</p></center>
                <input onChange={(e)=>{
                  setFrontDust(e.target.value)
                  CustomColorFrontDust(e.target.value)
                
                }} type="color" className='extramargin'/>
                <p>Change Color of Left Magnetic Dust Cover</p>
                <div onClick={()=>BRGColorforLeftDust()} className='color British_Racing_Green'></div>
                <div onClick={()=>BlackColorforLeftDust()} className='color Black'></div>
                <div onClick={()=>WhiteColorforLeftDust()} className='color White'></div>
                <div onClick={()=>RedColorforLeftDust()} className='color Red'></div>
                <div onClick={()=>LimeColorforLeftDust()}className='color Lime'></div>
                <div onClick={()=>YellowColorforLeftDust()} className='color Yellow'></div>
                <div onClick={()=>BlueColorforLeftDust()} className='color Blue'></div>
                <div onClick={()=>OrangeColorforLeftDust()} className='color Orange'></div>
                <div onClick={()=>GoldColorforLeftDust()} className='color Gold'></div>
                <div onClick={()=>SilverColorforLeftDust()} className='color Silver'></div>
                <div onClick={()=>GMGColorforLeftDust()} className='color Gun_Metal_Grey'></div>
                <center><p>Add Custom Color</p></center>
                <input onChange={(e)=>{
                  setLeftDust(e.target.value)
                  CustomColorLeftDust(e.target.value)
                
                }} type="color" className='extramargin'/>
                <p>Change Color of Right Magnetic Dust Cover</p>
                <div onClick={()=>BRGColorforRightDust()} className='color British_Racing_Green'></div>
                <div onClick={()=>BlackColorforRightDust()} className='color Black'></div>
                <div onClick={()=>WhiteColorforRightDust()} className='color White'></div>
                <div onClick={()=>RedColorforRightDust()} className='color Red'></div>
                <div onClick={()=>LimeColorforRightDust()}className='color Lime'></div>
                <div onClick={()=>YellowColorforRightDust()} className='color Yellow'></div>
                <div onClick={()=>BlueColorforRightDust()} className='color Blue'></div>
                <div onClick={()=>OrangeColorforRightDust()} className='color Orange'></div>
                <div onClick={()=>GoldColorforRightDust()} className='color Gold'></div>
                <div onClick={()=>SilverColorforRightDust()} className='color Silver'></div>
                <div onClick={()=>GMGColorforRightDust()} className='color Gun_Metal_Grey'></div>
                <center><p>Add Custom Color</p></center>
                <input onChange={(e)=>{
                  setRightDust(e.target.value)
                  CustomColorRightDust(e.target.value)
                
                }} type="color" className='extramargin'/>
            </div>
        </div>
    </>
  ); 
}

export default Color