import React from 'react'
import {ref,uploadBytesResumable,getDownloadURL } from "firebase/storage"
import {v4} from "uuid";
import {storage} from "../firebase"
const Graphics = ({app}) => {
  const [graphicsMonitor,setGraphicsMonitor]=React.useState("");
  // const [graphics4SidePanels,set4SidePanels]=React.useState("");
  const [toprightgraphics,settoprightgraphics]=React.useState("");
  const [bottomrightgraphics,setbottomrightgraphics]=React.useState("");
  const [bottomleftgraphics,setbottomleftgraphics]=React.useState("");
  const [topleftgraphics,settopleftgraphics]=React.useState("");

  const uploadTopLeftGraphics= async(e)=>{
    const file = e.target.files[0];
    const base64 = await convertBase64(file);
    settopleftgraphics(e.target.files[0]);
  }
  const uploadTopRightGraphics= async(e)=>{
    const file = e.target.files[0];
    const base64 = await convertBase64(file);
    settoprightgraphics(e.target.files[0]);
  }
  const uploadBottomLeftGraphics= async(e)=>{
    const file = e.target.files[0];
    const base64 = await convertBase64(file);
    setbottomleftgraphics(e.target.files[0]);
  }
  const uploadBottomRightGraphics= async(e)=>{
    const file = e.target.files[0];
    const base64 = await convertBase64(file);
    setbottomrightgraphics(e.target.files[0]);
  }

  const uploadMonitorGraphics= async(e)=>{
    const file = e.target.files[0];
    const base64 = await convertBase64(file);
    setGraphicsMonitor(e.target.files[0]);
  }
  // const upload4SidePanelsGraphics= async(e)=>{
  //   const file = e.target.files[0];
  //   const base64 = await convertBase64(file);
  //   set4SidePanels(base64);
  // }

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  // const Remove4SidePanelsGraphics = () => {
  //   console.log("Remove4SidePanelsGraphics");
  //   app.contentWindow.postMessage("Remove4SidePanelsGraphics", "*");
  // };
  const RemoveMonitorGraphics = () => {
    console.log("RemoveMonitorGraphics");
    app.contentWindow.postMessage("RemoveMonitorGraphics", "*");
  };
  const RemoveTopLeftGraphics = () => {
    console.log("RemoveTopLeftGraphics");
    app.contentWindow.postMessage("RemoveTopLeftGraphics", "*");
  };
  const RemoveTopRightGraphics = () => {
    console.log("RemoveTopRightGraphics");
    app.contentWindow.postMessage("RemoveTopRightGraphics", "*");
  };
  const RemoveBottomLeftGraphics = () => {
    console.log("RemoveBottomLeftGraphics");
    app.contentWindow.postMessage("RemoveBottomLeftGraphics", "*");
  };
  const RemoveBottomRightGraphics = () => {
    console.log("RemoveBottomRightGraphics");
    app.contentWindow.postMessage("RemoveBottomRightGraphics", "*");
  };










  const GraphicsMonitor = () => {
    if(graphicsMonitor==null)return ;
    const LogoRef =ref(storage,`images/${graphicsMonitor.name + v4()}`)
    const uploadTask = uploadBytesResumable(LogoRef, graphicsMonitor);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
          const percent = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );

          // update progress
          
      },
      (err) => console.log(err),
      () => {
          // download url
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
              setGraphicsMonitor(url);
              console.log("GraphicsMonitor");
    app.contentWindow.postMessage("GraphicsMonitor : "+url, "*");
          });
      }
  ); 
    
  };

  // const Graphics4SidePanel = () => {
  //   console.log("Graphics4SidePanel");
  //   app.contentWindow.postMessage("Graphics4SidePanel : "+graphics4SidePanels, "*");
  // };

  const GraphicsTopLeftPanel = () => {
    if(topleftgraphics==null)return ;
    const LogoRef =ref(storage,`images/${topleftgraphics.name + v4()}`)
    const uploadTask = uploadBytesResumable(LogoRef, topleftgraphics);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
          const percent = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );

          // update progress
          
      },
      (err) => console.log(err),
      () => {
          // download url
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
              settopleftgraphics(url);
              console.log("GraphicsTopLeftPanel");
    app.contentWindow.postMessage("GraphicsTopLeftPanel : "+url, "*");
          });
      }
  ); 
    
  };
  const GraphicsTopRightPanel = () => {
    if(toprightgraphics==null)return ;
    const LogoRef =ref(storage,`images/${toprightgraphics.name + v4()}`)
    const uploadTask = uploadBytesResumable(LogoRef, toprightgraphics);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
          const percent = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );

          // update progress
          
      },
      (err) => console.log(err),
      () => {
          // download url
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
              settoprightgraphics(url);
              console.log("GraphicsTopRightPanel");
    app.contentWindow.postMessage("GraphicsTopRightPanel : "+url, "*");
          });
      }
  ); 
    
  };
  const GraphicsBottomLeftPanel = () => {
    if(bottomleftgraphics==null)return ;
    const LogoRef =ref(storage,`images/${bottomleftgraphics.name + v4()}`)
    const uploadTask = uploadBytesResumable(LogoRef, bottomleftgraphics);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
          const percent = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );

          // update progress
          
      },
      (err) => console.log(err),
      () => {
          // download url
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
              setbottomleftgraphics(url);
              console.log("GraphicsBottomLeftPanel");
    app.contentWindow.postMessage("GraphicsBottomLeftPanel : "+url, "*");
          });
      }
  ); 
    
  };
  const GraphicsBottomRightPanel = () => {
    if(bottomrightgraphics==null)return ;
    const LogoRef =ref(storage,`images/${bottomrightgraphics.name + v4()}`)
    const uploadTask = uploadBytesResumable(LogoRef, bottomrightgraphics);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
          const percent = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );

          // update progress
          
      },
      (err) => console.log(err),
      () => {
          // download url
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
              setbottomrightgraphics(url);
              console.log("GraphicsBottomRightPanel");
    app.contentWindow.postMessage("GraphicsBottomRightPanel : "+url, "*");
          });
      }
  ); 
    
  };

  return (
    <>
        <div id='Graphics'>
            <div className="row">
                
                
                    <p>Graphics to the back of the Monitor</p>
                    <input onChange={(e)=>uploadMonitorGraphics(e)}  className='form-control' type="file"/>
                    <button className='buttons'onClick={()=>GraphicsMonitor()}>Upload Graphics to the back of the Monitor</button>
                    <button className='buttons'onClick={()=>RemoveMonitorGraphics()}>Remove Graphics to the back of the Monitor</button>
                    {/* <p>Graphics to the Top Left Side Panel</p>
                    <input onChange={(e)=>uploadTopLeftGraphics(e)}  className='form-control' type="file"/>
                    <button className='buttons'onClick={()=>GraphicsTopLeftPanel()}>Upload Graphics to the Top Left Side Panel</button>
                    <button className='buttons'onClick={()=>RemoveTopLeftGraphics()}>Remove Graphics from the Top Left Side Panel</button>
                    <p>Graphics to the Top Right Side Panel</p>
                    <input onChange={(e)=>uploadTopRightGraphics(e)}  className='form-control' type="file"/>
                    <button className='buttons'onClick={()=>GraphicsTopRightPanel()}>Upload Graphics to the Top Right Side Panel</button>
                    <button className='buttons'onClick={()=>RemoveTopRightGraphics()}>Remove Graphics from the Top Right Side Panel</button>
                    <p>Graphics to the Bottom Left Side Panel</p>
                    <input onChange={(e)=>uploadBottomLeftGraphics(e)}  className='form-control' type="file"/>
                    <button className='buttons'onClick={()=>GraphicsBottomLeftPanel()}>Upload Graphics to the Bottom Left Side Panel</button>
                    <button className='buttons'onClick={()=>RemoveBottomLeftGraphics()}>Remove Graphics from the Bottom Left Side Panel</button>
                    <p>Graphics to the Bottom Right Side Panel</p>
                    <input onChange={(e)=>uploadBottomRightGraphics(e)}  className='form-control' type="file"/>
                    <button className='buttons'onClick={()=>GraphicsBottomRightPanel()}>Upload Graphics to the Bottom Right Side Panel</button>
                    <button className='buttons'onClick={()=>RemoveBottomRightGraphics()}>Remove Graphics from the Bottom Right Side Panel</button> */}


            </div>
        </div>
    </>
  )
}

export default Graphics