import React from 'react'
import {storage} from "../firebase"
import {ref,uploadBytesResumable,getDownloadURL } from "firebase/storage"
import {v4} from "uuid";
const Logo = ({app,logo,setLogo,logoforMonitor,setLogoforMonitor,logoforTopSidePanels,setLogoforTopSidePanels,logoforBottomSidePanels,setLogoforBottomSidePanels,logoforSeatBack,setLogoforSeatBack}) => {
  

  const uploadLogo= async(e)=>{
    const file = e.target.files[0];
    const base64 = await convertBase64(file);
    setLogo(e.target.files[0]);
  }
  const uploadLogoforMonitor= async(e)=>{
    const file = e.target.files[0];
    const base64 = await convertBase64(file);
    setLogoforMonitor(e.target.files[0]);
  }
  const uploadLogoforTopSidePanels= async(e)=>{
    const file = e.target.files[0];
    const base64 = await convertBase64(file);
    setLogoforTopSidePanels(e.target.files[0]);
  }
  const uploadLogoforBottomSidePanels= async(e)=>{
    const file = e.target.files[0];
    const base64 = await convertBase64(file);
    setLogoforBottomSidePanels(e.target.files[0]);
  }
  const uploadLogoforSeatBack= async(e)=>{
    const file = e.target.files[0];
    const base64 = await convertBase64(file);
    setLogoforSeatBack(e.target.files[0]);
  }


  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  const RemoveLogo=()=>{
    console.log("RemoveLogo");
    app.contentWindow.postMessage("RemoveLogo", "*");
  }
  const RemoveLogoforMonitor=()=>{
    console.log("RemoveLogoforMonitor");
    app.contentWindow.postMessage("RemoveLogoforMonitor", "*");
  }
  const RemoveLogoforTopSidePanels=()=>{
    console.log("RemoveLogoforTopSidePanels");
    app.contentWindow.postMessage("RemoveLogoforTopSidePanels", "*");
  }
  const RemoveLogoforBottomSidePanels=()=>{
    console.log("RemoveLogoforBottomSidePanels");
    app.contentWindow.postMessage("RemoveLogoforBottomSidePanels", "*");
  }
  const RemoveLogoforSeatBack=()=>{
    console.log("RemoveLogoforSeatBack");
    app.contentWindow.postMessage("RemoveLogoforSeatBack", "*");
  }
  const Logo = () => {
    if(logo==null)return ;
    const LogoRef =ref(storage,`images/${logo.name + v4()}`)
    const uploadTask = uploadBytesResumable(LogoRef, logo);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
          const percent = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );

          // update progress
          
      },
      (err) => console.log(err),
      () => {
          // download url
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
              setLogo(url);
            console.log("Logo");
            app.contentWindow.postMessage("Logo : "+url, "*");
          });
      }
  ); 
    
  };

  const LogoforMonitor = () => {
    if(logoforMonitor==null)return ;
    const LogoRef =ref(storage,`images/${logoforMonitor.name + v4()}`)
    const uploadTask = uploadBytesResumable(LogoRef, logoforMonitor);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
          const percent = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );

          // update progress
          
      },
      (err) => console.log(err),
      () => {
          // download url
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
              setLogoforMonitor(url);
              console.log("LogoforMonitor");
            app.contentWindow.postMessage("LogoforMonitor : "+url, "*");
          });
      }
  ); 
    
  };
  const LogoforTopSidePanels = () => {
    if(logoforTopSidePanels==null)return ;
    const LogoRef =ref(storage,`images/${logoforTopSidePanels.name + v4()}`)
    const uploadTask = uploadBytesResumable(LogoRef, logoforTopSidePanels);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
          const percent = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );

          // update progress
          
      },
      (err) => console.log(err),
      () => {
          // download url
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
              setLogoforTopSidePanels(url);
              console.log("LogoforTopSidePanels");
    app.contentWindow.postMessage("LogoforTopSidePanels : "+url, "*");
          });
      }
  ); 
    
  };
  const LogoforBottomSidePanels = () => {
    if(logoforBottomSidePanels==null)return ;
    const LogoRef =ref(storage,`images/${logoforBottomSidePanels.name + v4()}`)
    const uploadTask = uploadBytesResumable(LogoRef, logoforBottomSidePanels);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
          const percent = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );

          // update progress
          
      },
      (err) => console.log(err),
      () => {
          // download url
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
              setLogoforBottomSidePanels(url);
              console.log("LogoforBottomSidePanels");
    app.contentWindow.postMessage("LogoforBottomSidePanels : "+url, "*");
          });
      }
  ); 
    
  };

  const LogoforSeatBack = () => {
    if(logoforSeatBack==null)return ;
    const LogoRef =ref(storage,`images/${logoforSeatBack.name + v4()}`)
    const uploadTask = uploadBytesResumable(LogoRef, logoforSeatBack);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
          const percent = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );

          // update progress
          
      },
      (err) => console.log(err),
      () => {
          // download url
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
              setLogoforSeatBack(url);
              console.log("LogoforSeatBack");
    app.contentWindow.postMessage("LogoforSeatBack : "+url, "*");
          });
      }
  ); 
    
  };
  return (
    <>
        <div id="Logo">
            <div className="row">
                
                    <p>Main Logo</p>
                    <center><span style={{fontSize:"10px",marginBottom:"5px"}}>Size Height x Width (1000px x 1000px)</span></center>
                    <input onChange={(e)=>uploadLogo(e)}  className='form-control' type="file"/>
                    <button className='buttons'  onClick={()=>Logo()}>Upload Logo</button>
                    <button className='buttons' onClick={()=>RemoveLogo()}>Remove Logo</button>


                    <p>Logo for the Back of the Monitor</p>
                    <center><span style={{fontSize:"10px",marginBottom:"5px"}}>Size Height x Width (1000px x 1000px)</span></center>
                    <input onChange={(e)=>uploadLogoforMonitor(e)}  className='form-control' type="file"/>
                    <button className='buttons' onClick={()=>LogoforMonitor()}>Upload Logo for the back of the Monitor</button>
                    <button className='buttons' onClick={()=>RemoveLogoforMonitor()}>Remove Logo for the back of the Monitor</button>

                    <p>Logo for Top Side Panels</p>
                    <center><span style={{fontSize:"10px",marginBottom:"5px"}}>Size Height x Width (400px x 1000px)</span></center>
                    
                    <input onChange={(e)=>uploadLogoforTopSidePanels(e)}  className='form-control' type="file"/>
                    <button className='buttons' onClick={()=>LogoforTopSidePanels()}>Upload Logo for Top Side Panels</button>
                    <button className='buttons'  onClick={()=>RemoveLogoforTopSidePanels()}>Remove Logo for Top Side Panels</button>

                    <p>Logo for Bottom Side Panels</p>
                    <center><span style={{fontSize:"10px",marginBottom:"5px"}}>Size Height x Width (400 x 1000px)</span></center>
                    
                    <input onChange={(e)=>uploadLogoforBottomSidePanels(e)}  className='form-control' type="file"/>
                    <button className='buttons' onClick={()=>LogoforBottomSidePanels()}>Upload Logo for Bottom Side Panels</button>
                    <button  className='buttons' onClick={()=>RemoveLogoforBottomSidePanels()}>Remove Logo for Bottom Side Panels</button>

                    <p>Logo for Back of Seat</p>
                    <center><span style={{fontSize:"10px",marginBottom:"5px"}}>Size Height x Width (1200px x 800px)</span></center>
                    
                    <input onChange={(e)=>uploadLogoforSeatBack(e)}  className='form-control' type="file"/>
                    <button className='buttons'onClick={()=>LogoforSeatBack()}>Upload Logo for the back of the Seat</button>
                    <button className='buttons'onClick={()=>RemoveLogoforSeatBack()}>Remove Logo for the back of the Seat</button>
                
                    {/* <p>Upload Logo</p>
                    <input  className='form-control' type="file"/> */}
                
            </div>
        </div>
    </>
  )
}

export default Logo