import React from 'react'
import CustomAccordion from '../Components/CustomAccordion'

import { BsFillCameraFill } from 'react-icons/bs';
import "./style.scss"
import { Button, Modal } from 'react-bootstrap';
import $ from "jquery"
import Signup from '../Components/Signup';
import Login from '../Components/Login';
import  Axios  from 'axios';
import swal from 'sweetalert';
const Home = () => {
  const [modalShow, setModalShow] = React.useState(false);
  const [signup,setSignup]=React.useState(false);
  const [login,setLogin]=React.useState(true);
  const [dashboard,setDashboard]=React.useState(false);
  const [loginButton,setLoginButton]=React.useState(false);
  const [logOutButton,setLogOutButton]=React.useState(true);
  const [users,setUsers]=React.useState([]);
  const [updateArea,setUpdatearea]=React.useState(false);
  const [newUserName,setNewUserName]=React.useState("");
  const [newUserEmail,setNewUserEmail]=React.useState("");
  const [newUserPassword,setNewUserPassword]=React.useState("");
  const [updatedValueID,setUpdatedValueID]=React.useState("");
  const [usersDashboard,setUsersDashboard]=React.useState(true);
  const [configurationsDashboard,setConfigurationsDashboard]=React.useState(false);
  const [loggedinUserDashboard,setLoggedInUserDashboard]=React.useState(false);
  const [loggedinUserId,setLoggedinUserID]=React.useState("none"); 
  const [loggedinUserName,setLoggedinUserName]=React.useState(""); 
  const [configurationdetails,setConfigurationDetails]=React.useState([]);
  const [refresh,setRefresh]=React.useState(false);
  const [ConfigurationDetailsSingle,setConfigurationDetailsSingle]=React.useState("");
  const [allConfigs,setAllConfigs]=React.useState([]);
  const [dataChange,setDataChange]=React.useState(0);
  const [userdataChange,setUserDataChange]=React.useState(0);
  const [count,setCount]=React.useState(0);
  React.useEffect(()=>{
    // Axios({
    //   method: 'get',
    //   headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    //   url: `https://carconfigurator.herokuapp.com/configurationdetails/${loggedinUserId}`,
      
    // }).then(function (response) {
    //   ;
    //   setConfigurationDetails(response.data);
    // });
    
    Axios({
      method: 'get',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      url: 'https://carconfigurator.herokuapp.com/allconfigs',
      
    }).then(function (response) {
      
      setAllConfigs(response.data);
    });


},[])
React.useEffect(()=>{
  Axios({
    method: 'get',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    url: 'https://carconfigurator.herokuapp.com/users',
    
  }).then(function (response) {
    
    setUsers(response.data);
    console.log(userdataChange);
      console.log("userdataChange");
  });
},[])
  const UpdateUser=(id)=>{
    Axios.put("https://carconfigurator.herokuapp.com/update",{
      id:id,
      newUserName:newUserName,
      newUserEmail:newUserEmail,
      newUserPassword:newUserPassword,

    })
  }
    const GetSingleConfiguration=(id)=>{
      Axios.get(`https://carconfigurator.herokuapp.com/configurationdetailssingle/${loggedinUserId}/${id}`).then(async(response)=>{
          setConfigurationDetailsSingle(response.data);
         
          
        });
    }
    const GetALLUsers=()=>{
      Axios({
        method: 'get',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        url: 'https://carconfigurator.herokuapp.com/users',
        
      }).then(function (response) {
        
        setUsers(response.data);
      });
    }

    const ConfigurationDetails=(id)=>{
      // Axios.get(`https://carconfigurator.herokuapp.com/configurationdetails/${id}`).then(async(response)=>{
      //   setConfigurationDetails(response.data);
      // });
      Axios({
          method: 'get',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          url: `https://carconfigurator.herokuapp.com/configurationdetails/${id}`,
          
        }).then(function (response) {
          
          setConfigurationDetails(response.data);
          
        });
    }
  const GETID=(id)=>{
    setUpdatedValueID(id);
  }
  const DeleteUser=(id)=>{
    Axios.delete(`https://carconfigurator.herokuapp.com/delete/${id}`)
    setRefresh(true);
  }
  const DeleteConfiguration=(id)=>{
    
    Axios.delete(`https://carconfigurator.herokuapp.com/deleteconfig/${id}`)
    setRefresh(true);
    ConfigurationDetails(loggedinUserId);
    console.log(configurationdetails)
  }

  React.useEffect(() => {
   Axios({
      method: 'get',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      url: `https://carconfigurator.herokuapp.com/configurationdetails/${loggedinUserId}`,
      
    }).then(function (response) {
      ;
      setConfigurationDetails(response.data);
      console.log(dataChange);
      console.log("dataChange");
      
    });
  },[dataChange])
  
  


  function MyVerticallyCenteredModal(props) {
    return (
      
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {/* <Modal.Header closeButton>
          <Modal.Title style={{color:"white"}} id="contained-modal-title-vcenter">
          </Modal.Title>
        </Modal.Header> */}
        <Modal.Body id="LoginModal">
          
          <section>
            <ul style={{display:"flex",backgroundColor:"#ff0000"}}>
              <li onClick={()=>{setSignup(true);setLogin(false);}}>SignUp</li>
              <li onClick={()=>{setSignup(false);setLogin(true);}}>Login</li>
            </ul>  
          </section> 
          {(()=>{
            if(signup){
              return(
                <>
                  <Signup setModalShow={setModalShow} setDashboard={setDashboard}/>
                </>
              )
            }else{
              return(
                <>
                <Login setDataChange={setDataChange} setCount={setCount} setConfigurationDetails={setConfigurationDetails} setLogOutButton={setLogOutButton} setLoggedinUserName={setLoggedinUserName} setLoggedinUserID={setLoggedinUserID} app={app} setLoginButton={setLoginButton} setLoggedInUserDashboard={setLoggedInUserDashboard} setDashboard={setDashboard} setModalShow={setModalShow}/>
                </>
              )
            }
          })()}  
       
          
        
          
        </Modal.Body>
        {/* <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer> */}
      </Modal>
    );
  }
  
  const [app, setApp] = React.useState("");
  const SnapShot=()=>{
    console.log("SnapShot");
    app.contentWindow.postMessage("SnapShot","*");
  }
  React.useEffect(() => {
      if (
          document.getElementById("app") &&
          document.getElementById("app").childNodes &&
          document.getElementById("app").childNodes.length > 0
    ) {
        setApp(document.getElementById("app").childNodes[0]);
        

        
    }
  }, []);
  return (

    <>
    

   
    <Modal 
    
      show={updateArea}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
      <div id="Updatearea">
      <div className='headerDashboard'>
            <h2>Update User Info</h2>
            <Button onClick={()=>setUpdatearea(false)} >Close</Button>
            </div>
            <hr/>
            <form>
              <label>Username : </label>
              <input onChange={(e)=>setNewUserName(e.target.value)} type="text" className='form-control' required/>
              <label>Email : </label>
              <input onChange={(e)=>setNewUserEmail(e.target.value)} type="email" className='form-control' required />
              <label>Password : </label>
              <input onChange={(e)=>setNewUserPassword(e.target.value)} type="text" className='form-control'  required/>
              <Button onClick={()=>UpdateUser(updatedValueID)} className="btn btn-danger" type="submit" >Update User</Button>
            </form>
      </div>
      </Modal.Body>
    </Modal>
    


    <Modal
        show={dashboard}
        onHide={() => setDashboard(false)}
        fullscreen={true}
        dialogClassName="modal-120w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        
        <Modal.Body>
          <div id="Dashboard">
            <div className='headerDashboard'>
            <h2>ADMIN DASHBOARD</h2>
            <Button onClick={()=>setDashboard(false)} style={{backgroundColor:"#ff0000",borderRadius:"0px",border:"none"}}>Close</Button>
            </div>

            <hr/>
            <center><span onClick={()=>{
              setConfigurationsDashboard(false);
              setUsersDashboard(true);
            }}>Users</span>
            <span onClick={()=>{
               setConfigurationsDashboard(true);
               setUsersDashboard(false);
            }}>Configurations</span></center>
            <hr/>
            {(()=>{
              if(usersDashboard){
                return(
                  <table className="table">
              <thead className="thead-dark">
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Password</th>
                  
                </tr>
              </thead>
              <tbody>
                {users.map((val,key)=>{
                  return (
                   
                      <tr key={key} style={{cursor:"pointer",alignItems:"center"}}>
                      <th scope="row">{key+1}</th>
                      <td>{val.userName}</td>
                      <td>{val.userEmail}</td>
                      <td>{val.userPassword}</td>
                      <td><Button onClick={()=>{setUpdatearea(true);
                            GETID(val._id)}

                      } className="editButton">Edit</Button></td>
                      <td><Button onClick={()=>{
                        
                        DeleteUser(val._id);
                        setUserDataChange(userdataChange+1)
                      // setDashboard(false);
                      // window.location.reload();
                      
                    }
                      }  className="deletebutton" >Delete</Button></td>
                    </tr>
                    
                  )
                })}
              </tbody>
            </table>
                )
              }if(configurationsDashboard){
                return(
                  <table className="table">
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Name</th>
                      <th scope="col">Configuration Name</th>
                      <th scope="col">Config Date</th> 
                    </tr>
                  </thead>
                  <tbody>
                    {allConfigs.map((val,key)=>{
                      return (
                       
                          <tr key={key} style={{cursor:"pointer",alignItems:"center"}}>
                          <th scope="row">{key+1}</th>
                          <td>{val.username}</td> 
                          <td>{val.userconfiguration[36]}</td> 
                          <td>{val.configDate}</td>
                          <td><Button onClick={()=>{
                        GetSingleConfiguration(val._id);
              
                        console.log("ConfigurationDatafromDatabase"+val.userconfiguration);
                        app.contentWindow.postMessage("ConfigurationDatafromDatabase : "+val.userconfiguration,"*");
                        setLoggedInUserDashboard(false);
                        swal("Loaded!", "Configurator Successfully Loaded from the Database!", "success");
                      }}

                      className="deletebutton">Load</Button></td>
                        </tr>
                        
                      )
                    })}
                  </tbody>
                </table>
                )
              }
            })()}
            
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={loggedinUserDashboard}
        onHide={() => setLoggedInUserDashboard(false)}
        fullscreen={true}
        dialogClassName="modal-120w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        
        <Modal.Body>
          <div id="LoggedInUserDashboard">
            <div className='headerDashboard'>
            <h2>USER DASHBOARD</h2>
            <Button onClick={()=>setLoggedInUserDashboard(false)} className="deletebutton">Close</Button>
            </div>
            <hr/>
            {(()=>{
              if(loggedinUserDashboard){
                return(
            <div>
              <table  className="table">
              <thead  className="thead-dark">
                <tr >
                  <th scope="col">#</th>
                  <th scope="col">Name</th>
                  <th scope="col">Configuration Name</th>
                  <th scope="col">Configuration Date</th>
                </tr>
              </thead>
              <tbody> 
                 {configurationdetails.map((val,key)=>{
                  return ( 
                   
                      <tr   key={key} style={{cursor:"pointer",alignItems:"center"}}>
                      <th scope="row">{key+1}</th>
                      <td>{loggedinUserName}</td>
                      <td>{val.userconfiguration[36]}</td>
                      <td>{val.configDate}</td>
                      
                      <td><Button  className="editButton" onClick={()=>{
                        
                        DeleteConfiguration(val._id);
                        setDataChange(dataChange+1)
                        
                        // setLoggedInUserDashboard(false);
                        // ConfigurationDetails(loggedinUserId);

                        // window.location.reload(); 
                      }}>Delete</Button></td>
                      <td><Button onClick={()=>{
                        GetSingleConfiguration(val._id);
              
                        console.log("ConfigurationDatafromDatabase"+val.userconfiguration);
                        app.contentWindow.postMessage("ConfigurationDatafromDatabase : "+val.userconfiguration,"*");
                        setLoggedInUserDashboard(false);
                        swal("Loaded!", "Configurator Successfully Loaded from the Database!", "success");
                      }}

                      className="deletebutton">Load</Button></td>
                    </tr>
                    
                  )
                })} 
              </tbody>
            </table>
            </div>
                )
              }if(configurationsDashboard){
                return(
                  ""
                )
              }
            })()}
            
          </div>
        </Modal.Body>
      </Modal>
    <div id="Home">
      <div className='row'>
        <div id="app" className='col-md-9 col-sm-12'>
          <iframe  src="https://playcanv.as/e/p/FcBIzAS9/"   loading="lazy" ></iframe>
          
        </div>
        <div  className='col-md-3 col-sm-12 ConfiguratorOptions'>
          {/* <Header/> */}
          <div  className='LoginButton'>
            <center>{(()=>{
              if(dashboard){
                return(
                  ""
                )
              }else{
                return(
                  <>
                  <button hidden={loginButton}  style={{width:"100%",borderRadius:"0%"}} onClick={() => {setModalShow(true);}}className='form-control'>Login</button>
                  <button hidden={logOutButton}   style={{width:"100%"}} onClick={() => {window.location.reload();}}className='form-control'>Logout {loggedinUserName}</button>
                  <button hidden={logOutButton} onClick={()=>{if(count===1){
                    setDashboard(true);
                    console.log(loggedinUserId)
                  }else{
                    setLoggedInUserDashboard(true)
                    ConfigurationDetails(loggedinUserId);
                    
                  }}}  style={{width:"100%"}} className='form-control'>DASHBOARD</button>
                  </>
                )
              }
            })()}</center>
          </div>
          <CustomAccordion ConfigurationDetails={ConfigurationDetails} setLoggedInUserDashboard={setLoggedInUserDashboard} allConfigs={allConfigs} setAllConfigs={setAllConfigs} loggedinUserName={loggedinUserName} loggedinUserId={loggedinUserId} app={app}/>
          <MyVerticallyCenteredModal
            show={modalShow}
            onHide={() => setModalShow(false)}
          />
         
        </div>
      </div>
    </div>
    <div className='SnapShotIcon'>
    <BsFillCameraFill  onClick={()=>SnapShot()} style={{color:"white",position:"fixed",bottom:"1rem",fontSize:"3rem",right:"50%"}}/>
    </div>
    </>
  )
}

export default Home